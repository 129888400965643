import { Pagination } from '@postscript/components';
import { PaginationWrapper, SkeletonCard } from '../styles';

interface InsightsPaginationProps {
  totalItems: number;
  pageSize: number;
  page: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

export default function InsightsPagination({
  totalItems,
  pageSize,
  page,
  onPageChange,
  isLoading,
}: InsightsPaginationProps) {
  const totalPages = Math.ceil(totalItems / pageSize);

  if (isLoading) {
    return (
      <PaginationWrapper>
        <SkeletonCard height={40} />
      </PaginationWrapper>
    );
  }

  if (totalItems === 0) {
    return null;
  }

  return (
    <PaginationWrapper>
      <Pagination
        currentPage={page}
        selectPage={onPageChange}
        size="medium"
        showFirstLastPageButtons
        totalPages={totalPages}
      />
    </PaginationWrapper>
  );
}
