export const CUSTOM_DATE_PROPERTY_EVENT_TYPE = 'CustomDateProperty';
export const PRICE_DROP_EVENT_TYPE = 'PriceDrop';
export const SUBSCRIBER_CLICKED_LINK_EVENT_TYPE = 'SubscriberClickedLink';

export const PRODUCT_LOW_INVENTORY_EVENT_TYPE = 'ProductLowInventory';
export const VARIANT_LOW_INVENTORY_EVENT_TYPE = 'VariantLowInventory';

export const LOW_INVENTORY_EVENT_TYPES = [
  PRODUCT_LOW_INVENTORY_EVENT_TYPE,
  VARIANT_LOW_INVENTORY_EVENT_TYPE,
];

export const UNIFIED_LOW_INVENTORY_EVENT_NAME = 'Low Inventory';
export const UNIFIED_LOW_INVENTORY_EVENT_DESCRIPTION =
  'By default, this event triggers for any in-stock product or variant quantity that drops as a result of changes made in Shopify.';

export const TRANSACTIONAL_MESSAGE_ELIGIBLE_EVENT_TYPES = [
  'AftershipDelivered',
  'AftershipFailedAttempt',
  'AftershipInTransit',
  'AftershipOutForDelivery',
  'RechargeChargeCreated',
  'RechargeChargePaid',
  'RechargeChargeUpcoming',
  'RechargeCustomerActivated',
  'RechargeCustomerDeactivated',
  'RechargeOrderCreated',
  'ShopifyAttemptedDelivery',
  'ShopifyFulfillmentCreated',
  'ShopifyOrderCreated',
  'ShopifyOrderDelivered',
  'ShopifyOrderFulfilled',
  'ShopifyOrderInTransit',
  'ShopifyOrderOutForDelivery',
  'ShopifyOrderReadyForPickup',
];

export const DISALLOWED_CANCELLATION_EVENT_TYPES = [
  CUSTOM_DATE_PROPERTY_EVENT_TYPE, // We're currently not supporting custom date properties for cancellation events (FBP-1163)
  PRICE_DROP_EVENT_TYPE, // We're currently not supporting price drop for cancellation events (FBP-1505)
  ...LOW_INVENTORY_EVENT_TYPES, // We're currently not supporting low inventory for cancellation events
];

export const RECOMMENDED_CANCELLATION_EVENT_TYPES = ['SubscriberViewedProduct'];

// For compliance reasons, we restrict non-determinate wait categories for some events
export const WAIT_TIMEDELTA_ONLY_EVENT_TYPES = [
  'ProductAddedToCart',
  'RechargeCheckoutStarted',
  'ShopifyCheckoutStarted',
  'SubscriberViewedProduct',
];

export const DISALLOWED_WAIT_FOR_EVENT_TYPES = [
  'AddedViaAnotherFlow',
  'ProductAddedToCart',
  'RechargeCheckoutStarted',
  'ShopifyCheckoutStarted',
  'SubscriberCreated',
  'SubscriberViewedProduct',
  CUSTOM_DATE_PROPERTY_EVENT_TYPE,
  PRICE_DROP_EVENT_TYPE,
  ...LOW_INVENTORY_EVENT_TYPES,
];

export const AI_MANAGED_MESSAGE_ALLOWED_EVENT_TYPES = [
  'SubscriberViewedProduct',
  'ShopifyCheckoutStarted',
];
