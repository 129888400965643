export enum AnalyticsTypesNames {
  CONVERSION_RATE = 'conversion_rate',
  CREDITS_USED = 'credits_used',
  CTR = 'ctr',
  DELIVERABILITY = 'deliverability',
  EPM = 'epm',
  MESSAGE_CONVERSION_RATE = 'mcvr',
  ORDERS = 'orders',
  OUTBOUND = 'outbound',
  PSPAY_ATTRIBUTED_REVENUE = 'pspay_attributed_revenue',
  PSPAY_ORDERS = 'pspay_orders',
  QUEUED_SUBSCRIBERS = 'queued_subscribers',
  REVENUE = 'revenue',
  TOTAL_CLICKS = 'total_clicks',
  TOTAL_FAILED = 'total_failed',
  TOTAL_SENT = 'total_sent',
  TOTAL_SUBSCRIBERS = 'total_subscribers',
  TOTAL_UNSUBS = 'total_unsubs',
  UCTR = 'uctr',
  UNIQUE_CLICKS = 'unique_clicks',
  UNIQUE_SUBSCRIBERS = 'unique_subscribers',
  UNSUBSCRIBE_RATE = 'unsubscribe_rate',
  VALID_MESSAGE_STATISTICS = 'valid_message_statistics',
}

export const StatNames = {
  [AnalyticsTypesNames.CONVERSION_RATE]: 'Click conversion rate',
  [AnalyticsTypesNames.CTR]: 'CTR',
  [AnalyticsTypesNames.DELIVERABILITY]: 'Deliverability',
  [AnalyticsTypesNames.EPM]: 'EPM',
  [AnalyticsTypesNames.MESSAGE_CONVERSION_RATE]: 'Msg conversion rate',
  [AnalyticsTypesNames.ORDERS]: 'Total orders',
  [AnalyticsTypesNames.OUTBOUND]: 'Pending',
  [AnalyticsTypesNames.PSPAY_ATTRIBUTED_REVENUE]: 'Text-to-Buy',
  [AnalyticsTypesNames.REVENUE]: 'Revenue',
  [AnalyticsTypesNames.TOTAL_CLICKS]: 'Total clicks',
  [AnalyticsTypesNames.TOTAL_SENT]: 'Sent messages',
  [AnalyticsTypesNames.TOTAL_SUBSCRIBERS]: 'Total subscribers',
  [AnalyticsTypesNames.UCTR]: 'UCTR',
  [AnalyticsTypesNames.UNSUBSCRIBE_RATE]: 'Unsubscribe rate',
} as const;

export const StatLongNames = {
  [AnalyticsTypesNames.EPM]: 'Earnings per message',
  [AnalyticsTypesNames.MESSAGE_CONVERSION_RATE]: 'Message conversion rate',
  [AnalyticsTypesNames.UCTR]: 'Unique click-through rate (UCTR)',
};

export const StatShortNames = {
  [AnalyticsTypesNames.CONVERSION_RATE]: 'Click conv.',
  [AnalyticsTypesNames.CTR]: 'CTR',
  [AnalyticsTypesNames.DELIVERABILITY]: 'Deliv.',
  [AnalyticsTypesNames.EPM]: 'EPM',
  [AnalyticsTypesNames.MESSAGE_CONVERSION_RATE]: 'Msg conv.',
  [AnalyticsTypesNames.ORDERS]: 'Orders',
  [AnalyticsTypesNames.OUTBOUND]: 'Pending',
  [AnalyticsTypesNames.PSPAY_ATTRIBUTED_REVENUE]: 'T2B',
  [AnalyticsTypesNames.REVENUE]: 'Rev.',
  [AnalyticsTypesNames.TOTAL_CLICKS]: 'Clicks',
  [AnalyticsTypesNames.TOTAL_SENT]: 'Sent',
  [AnalyticsTypesNames.TOTAL_SUBSCRIBERS]: 'Subscribers',
  [AnalyticsTypesNames.UCTR]: 'UCTR',
  [AnalyticsTypesNames.UNSUBSCRIBE_RATE]: 'Unsub.',
} as const;

export const AnalyticsGroupsTypesNames = {
  ORDERS_AND_CONVERSION_RATE: 'ORDERS_AND_CONVERSION_RATE',
  REVENUE_AND_EPM: 'REVENUE_AND_EPM',
  TOTAL_CLICKS_AND_CTR: 'TOTAL_CLICKS_AND_CTR',
  TOTAL_CLICKS_AND_CTR_AND_UCTR: 'TOTAL_CLICKS_AND_CTR_AND_UCTR',
  TOTAL_SENT_AND_DELIVERABILITY: 'TOTAL_SENT_AND_DELIVERABILITY',
  TOTAL_SENT_AND_UNSUBSCRIBE_RATE: 'TOTAL_SENT_AND_UNSUBSCRIBE_RATE',
  TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE:
    'TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE',
} as const;

// List of overall flow analytics displayed in the flows list view
export const FlowsListAnalyticsGroups = [
  AnalyticsGroupsTypesNames.TOTAL_SENT_AND_UNSUBSCRIBE_RATE,
  AnalyticsGroupsTypesNames.TOTAL_CLICKS_AND_CTR,
  AnalyticsGroupsTypesNames.ORDERS_AND_CONVERSION_RATE,
  AnalyticsGroupsTypesNames.REVENUE_AND_EPM,
] as const;

// List of overall flow analytics displayed in the flow view
export const OverallAnalyticsGroups = [
  AnalyticsGroupsTypesNames.REVENUE_AND_EPM,
  AnalyticsGroupsTypesNames.ORDERS_AND_CONVERSION_RATE,
  AnalyticsGroupsTypesNames.TOTAL_CLICKS_AND_CTR_AND_UCTR,
  AnalyticsGroupsTypesNames.TOTAL_SENT_AND_DELIVERABILITY,
  AnalyticsGroupsTypesNames.TOTAL_SUBSCRIBERS_AND_UNSUBSCRIBE_RATE,
] as const;

// List of action analytics displayed in the flow view
export const ActionAnalyticsTypesNames = [
  AnalyticsTypesNames.REVENUE,
  AnalyticsTypesNames.EPM,
  AnalyticsTypesNames.ORDERS,
  AnalyticsTypesNames.CONVERSION_RATE,
  AnalyticsTypesNames.MESSAGE_CONVERSION_RATE,
  AnalyticsTypesNames.TOTAL_CLICKS,
  AnalyticsTypesNames.CTR,
  AnalyticsTypesNames.UCTR,
  AnalyticsTypesNames.TOTAL_SENT,
  AnalyticsTypesNames.UNSUBSCRIBE_RATE,
] as const;

export const FlowAnalyticsTypesNames = {
  OVERALL: 'overall',
  OVERALL_SLIM: 'overall-slim',
  OUTBOUND: 'outbound',
  ACTIONS: 'actions',
} as const;

export const EMPTY_ANALYTICS = {
  [AnalyticsTypesNames.CREDITS_USED]: 0,
  [AnalyticsTypesNames.ORDERS]: 0,
  [AnalyticsTypesNames.REVENUE]: 0,
  [AnalyticsTypesNames.TOTAL_CLICKS]: 0,
  [AnalyticsTypesNames.TOTAL_SENT]: 0,
  [AnalyticsTypesNames.TOTAL_SUBSCRIBERS]: 0,
  [AnalyticsTypesNames.TOTAL_UNSUBS]: 0,
  [AnalyticsTypesNames.UNIQUE_CLICKS]: 0,
} as const;

export const ANALYTICS_REFETCH_INTERVAL = 1000 * 30; // 30 seconds
