import { Icon, IconSet } from '@postscript/components';
import styled from 'styled-components';
import MediaQueries from 'utils/mediaQueries';

const StyledNavMenuBub = styled.div`
  height: 30px;
  width: 30px;
  opacity: 0;
  position: fixed;
  z-index: 1000;
  top: calc(100vh - 36px);
  left: -30px;
  transition: opacity var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);

  ${MediaQueries.desktopAndTablet} {
    .full-screen-editor & {
      visibility: visible;
      transform: translateX(39px);
      opacity: 1;
    }
  }

  /* on sidebar open */

  .sidebar-navigation-open & {
    opacity: 0;
  }

  ${MediaQueries.desktopOnly} {
    .sidebar-navigation-open & {
      transform: translate(calc(var(--nav-menu-width) * 0.67), 0);
    }
  }

  /* svg color */

  svg {
    height: 100%;
  }
  svg > path:not(:first-of-type) {
    fill: var(--white);
  }
  svg > path:first-of-type {
    fill: var(--purple-core);
  }
`;

const NavMenuBub = () => {
  return (
    <StyledNavMenuBub aria-hidden="true" data-cy="nav-bub">
      <Icon component={IconSet.Bub} size={30} />
    </StyledNavMenuBub>
  );
};

export default NavMenuBub;
