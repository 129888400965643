import {
  BodyText,
  Button,
  EmptyState,
  Heading,
  Icon,
  IconSet,
  Layout,
  Tooltip,
} from '@postscript/components';
import { useState } from 'react';
import SentimentBar from '../../components/SentimentBar';
import {
  MAX_TAGS_TO_DISPLAY,
  TAGS_TAB_PANEL_ID,
  TOPICS_TAB_PANEL_ID,
} from '../constants';
import * as styles from '../styles';
import {
  Category,
  ComputedTag,
  ConversationIntelligenceMetricsData,
  InsightsResult,
  Tag,
  TopTags,
} from '../types';
import { formatPercentage, formatTopicLabel } from '../utils';

const removeMerchantNameAndDuplicates = (
  tags: TopTags,
  shopName: string,
): TopTags => {
  const cleanString = (str: string): string => {
    return str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .trim();
  };

  const cleanShopName = cleanString(shopName);
  const tagMap = new Map<string, Tag>();
  const excludedTags = ['positive', 'neutral', 'negative'];

  tags.tags.forEach((tag) => {
    const cleanTagName = cleanString(tag.name);

    if (
      cleanTagName.includes(cleanShopName) ||
      excludedTags.includes(cleanTagName)
    ) {
      return;
    }

    if (tagMap.has(cleanTagName)) {
      // Merge duplicate tags
      const existingTag = tagMap.get(cleanTagName);
      if (existingTag) {
        existingTag.count += tag.count;
      }
    } else {
      // Add new tag to the map
      tagMap.set(cleanTagName, { ...tag });
    }
  });

  const result = {
    total_conversations: tags.total_conversations,
    tags: Array.from(tagMap.values()),
  };

  return result;
};

const getTopicCategories = (result: InsightsResult | undefined): Category[] => {
  if (!result) return [];
  const { top_topics: topTopics } = result;

  const sortedCategories = topTopics.categories.map((category) => {
    const sortedTopics = category.topics.sort((a, b) => b.total - a.total);
    return { ...category, topics: sortedTopics };
  });

  // Step 2: Sort categories by the highest total topic within each category in descending order
  sortedCategories.sort((a, b) => {
    const highestTotalA = a.topics[0]?.total || 0;
    const highestTotalB = b.topics[0]?.total || 0;
    return highestTotalB - highestTotalA;
  });

  return sortedCategories;
};

interface TopicsTabProps {
  categories: Category[];
  metrics: ConversationIntelligenceMetricsData;
  numberOfDays: number;
  selectedTopicLabel?: string;
  handleTopicLabelClick: (topicLabel: string) => void;
}

const TopicsTab = ({
  categories,
  metrics,
  numberOfDays,
  selectedTopicLabel,
  handleTopicLabelClick,
}: TopicsTabProps): JSX.Element => {
  const noCategoriesToDisplay = !categories || categories.length <= 0;

  if (noCategoriesToDisplay) {
    return (
      <EmptyState
        description="No topics available for this time period"
        size="small"
      />
    );
  }
  return (
    <>
      {categories.map((category, index) => {
        const isFirstCategory = index === 0;
        return (
          <styles.TopicCategoryGroup>
            <styles.TopicCategoryTitle>
              <BodyText size="x-small">
                <strong>{category.name}</strong>
              </BodyText>
              {isFirstCategory && (
                <Layout gap="var(--spacing-2)" style={{ paddingRight: '3px' }}>
                  <Icon
                    color="var(--text-color-dim)"
                    size={18}
                    component={IconSet.Percentage}
                  />
                  <Icon
                    color="var(--text-color-dim)"
                    size={18}
                    component={IconSet.EmojiHappy}
                  />
                </Layout>
              )}
            </styles.TopicCategoryTitle>
            {category.topics.map((topic) => {
              const selected = topic.label === selectedTopicLabel;

              const percentage = formatPercentage(
                topic.total,
                metrics.total_conversations,
              );

              const positivePercentage = formatPercentage(
                topic.sentiments.positive,
                topic.total,
              );
              const neutralPercentage = formatPercentage(
                topic.sentiments.neutral,
                topic.total,
              );
              const negativePercentage = formatPercentage(
                topic.sentiments.negative,
                topic.total,
              );

              return (
                <styles.HashtagLineItem
                  highlighted={selected}
                  key={topic.label}
                >
                  <styles.Topic
                    tabIndex={0}
                    onClick={() => handleTopicLabelClick(topic.label)}
                    role="button"
                    href="#"
                    highlighted={selected}
                  >
                    <styles.TopicIcon
                      component={IconSet.Hashtag}
                      color={selected ? 'var(--white)' : 'var(--blue-5)'}
                    />

                    {formatTopicLabel(topic.label)}
                  </styles.Topic>
                  <div>
                    <styles.FreqSent
                      aria-describedby={`tooltip-${topic.label}`}
                      data-event={null}
                      data-for={`tooltip-${topic.label}`}
                      data-tip
                      tabIndex={0}
                    >
                      <BodyText
                        size="x-small"
                        color={
                          selected ? 'var(--white)' : 'var(--text-color-dim)'
                        }
                      >{`${percentage}%`}</BodyText>
                      <SentimentBar
                        negative={topic.sentiments.negative}
                        neutral={topic.sentiments.neutral}
                        positive={topic.sentiments.positive}
                      />
                    </styles.FreqSent>
                    <Tooltip id={`tooltip-${topic.label}`} place="bottom">
                      <BodyText
                        color="var(--color-text-tooltip)"
                        bold
                        size="x-small"
                      >
                        Frequency
                      </BodyText>
                      <BodyText
                        color="var(--color-text-tooltip)"
                        size="x-small"
                      >
                        {percentage}% · {topic.total} conversations
                        <br />
                        in the last {numberOfDays} days
                      </BodyText>

                      <BodyText
                        color="var(--color-text-tooltip)"
                        bold
                        size="x-small"
                        style={{
                          marginTop: 'var(--spacing-1)',
                          marginBottom: 'var(--spacing-1)',
                        }}
                      >
                        Sentiment
                      </BodyText>
                      <styles.SentimentList>
                        <styles.SentimentItem>
                          <styles.IconWrapper>
                            <styles.Circle color="var(--color-sentiment-icon-negative)">
                              <Icon
                                color="var(--black)"
                                size={14}
                                component={IconSet.FaceSad}
                              />
                            </styles.Circle>
                          </styles.IconWrapper>
                          <BodyText
                            color="var(--color-text-tooltip)"
                            size="x-small"
                          >
                            {negativePercentage}% negative
                          </BodyText>
                        </styles.SentimentItem>
                        <styles.SentimentItem>
                          <styles.IconWrapper>
                            <styles.Circle color="var(--color-sentiment-icon-neutral)">
                              <Icon
                                color="var(--black)"
                                size={14}
                                component={IconSet.FaceStraight}
                              />
                            </styles.Circle>
                          </styles.IconWrapper>
                          <BodyText
                            color="var(--color-text-tooltip)"
                            size="x-small"
                          >
                            {neutralPercentage}% neutral
                          </BodyText>
                        </styles.SentimentItem>
                        <styles.SentimentItem>
                          <styles.IconWrapper>
                            <styles.Circle color="var(--color-sentiment-icon-positive)">
                              <Icon
                                color="var(--black)"
                                size={14}
                                component={IconSet.FaceSmile}
                              />
                            </styles.Circle>
                          </styles.IconWrapper>
                          <BodyText
                            color="var(--color-text-tooltip)"
                            size="x-small"
                          >
                            {positivePercentage}% positive
                          </BodyText>
                        </styles.SentimentItem>
                      </styles.SentimentList>
                    </Tooltip>
                  </div>
                </styles.HashtagLineItem>
              );
            })}
          </styles.TopicCategoryGroup>
        );
      })}
    </>
  );
};

interface TagsTabProps {
  tags: ComputedTag[];
  handleTagClick: (tag: string) => void;
  selectedTag?: string;
}

const TagsTab = ({
  tags,
  selectedTag,
  handleTagClick,
}: TagsTabProps): JSX.Element => {
  const [isTagsExpanded, setIsTagsExpanded] = useState(false);

  const noTagsToDisplay = !tags || tags.length <= 0;

  return (
    <>
      {!noTagsToDisplay &&
        (isTagsExpanded
          ? tags.slice(0, MAX_TAGS_TO_DISPLAY)
          : tags.slice(0, 10)
        ).map((tag) => {
          const selected = tag.name === selectedTag;
          return (
            <styles.HashtagLineItem highlighted={selected} key={tag.name}>
              <styles.Topic
                tabIndex={0}
                onClick={() => handleTagClick(tag.name)}
                role="button"
                href="#"
                highlighted={selected}
              >
                <styles.TopicIcon
                  component={IconSet.Tag}
                  color={selected ? 'var(--white)' : 'var(--blue-5)'}
                />
                {formatTopicLabel(tag.name)}
              </styles.Topic>
              <styles.TopicPercentage
                highlighted={selected}
                aria-describedby={`tooltip-${tag.name}`}
                data-event={null}
                data-for={`tooltip-${tag.name}`}
                data-tip
                tabIndex={0}
              >{`${tag.percentage.toFixed(2)}%`}</styles.TopicPercentage>
              <Tooltip id={`tooltip-${tag.name}`} place="bottom">
                <BodyText color="var(--color-text-tooltip)" size="x-small">
                  {`Tag associated with ${tag.percentage.toFixed(
                    2,
                  )}% of conversations in this time period.`}
                </BodyText>
              </Tooltip>
            </styles.HashtagLineItem>
          );
        })}
      {tags.length > 10 && (
        <styles.ViewMoreTagsWrapper>
          <styles.ViewMoreHr />
          <styles.ViewMoreButtonWrapper>
            <Button
              onClick={() => setIsTagsExpanded(!isTagsExpanded)}
              iconPosition="right"
              icon={isTagsExpanded ? IconSet.ChevronUp : IconSet.ChevronDown}
              size="small"
              variant="text"
              monochrome
            >
              {isTagsExpanded
                ? 'View Less'
                : `View ${Math.min(
                    tags.length - 10,
                    MAX_TAGS_TO_DISPLAY - 10,
                  )} More`}
            </Button>
          </styles.ViewMoreButtonWrapper>
        </styles.ViewMoreTagsWrapper>
      )}
      {noTagsToDisplay && (
        <EmptyState
          description="No tags available for this time period"
          size="small"
        />
      )}
    </>
  );
};

const getTopTags = (
  insights: InsightsResult | undefined,
  shopName: string,
): ComputedTag[] => {
  if (!insights) {
    return [];
  }

  const { top_tags: topTags } = insights;

  const formattedTags = removeMerchantNameAndDuplicates(topTags, shopName);

  const totalConversations = formattedTags.total_conversations;
  const sortedTags = topTags.tags.sort((a, b) => b.count - a.count);

  return sortedTags.map((tag) => {
    const roundedPercentage =
      Math.round((tag.count / totalConversations) * 10000) / 100;

    return {
      name: tag.name,
      percentage: roundedPercentage,
    };
  });
};

interface Props {
  isLoading: boolean;
  insights?: InsightsResult;
  metrics: ConversationIntelligenceMetricsData;
  shopName: string;
  numberOfDays: number;
  handleTopicLabelClick: (topicLabel: string) => void;
  handleTagClick: (tag: string) => void;
  selectedTopicLabel?: string;
  selectedTag?: string;
}

export const ConversationIntelligenceInsights = ({
  isLoading,
  insights,
  shopName,
  numberOfDays,
  metrics,
  handleTopicLabelClick,
  handleTagClick,
  selectedTopicLabel,
  selectedTag,
}: Props): JSX.Element => {
  const [selectedTabGroup, setSelectedTabGroup] = useState<string>('topics');
  if (isLoading) {
    return <styles.SkeletonHashtagCard />;
  }

  const topTags = getTopTags(insights, shopName);
  const categories = getTopicCategories(insights);

  if (!insights) {
    return (
      <styles.HashtagCard>
        <styles.HashtagHeader>
          <Icon size="large" component={IconSet.Bulb} />
          <Heading size="xx-small">Insights</Heading>
        </styles.HashtagHeader>
        <EmptyState
          description="Topic insights begin generating after 500-1000 conversations"
          size="small"
        />
      </styles.HashtagCard>
    );
  }

  return (
    <styles.HashtagCard>
      <styles.HashtagHeader>
        <Icon size="large" component={IconSet.Bulb} />
        <Heading size="xx-small">Insights</Heading>
      </styles.HashtagHeader>
      <styles.TabGroup
        id="ci-tags-and-topics"
        description="View tags and topics"
      >
        <button
          data-selected={selectedTabGroup === 'topics'}
          data-tabpanel={TOPICS_TAB_PANEL_ID}
          onClick={() => setSelectedTabGroup('topics')}
          type="button"
        >
          Topics
        </button>
        <button
          data-selected={selectedTabGroup === 'tags'}
          data-tabpanel={TAGS_TAB_PANEL_ID}
          onClick={() => setSelectedTabGroup('tags')}
          type="button"
        >
          Tags
        </button>
      </styles.TabGroup>
      <styles.HashtagContainer>
        {selectedTabGroup === 'topics' && (
          <TopicsTab
            categories={categories}
            metrics={metrics}
            selectedTopicLabel={selectedTopicLabel}
            handleTopicLabelClick={handleTopicLabelClick}
            numberOfDays={numberOfDays}
          />
        )}
        {selectedTabGroup === 'tags' && (
          <TagsTab
            tags={topTags}
            selectedTag={selectedTag}
            handleTagClick={handleTagClick}
          />
        )}
      </styles.HashtagContainer>
    </styles.HashtagCard>
  );
};

export default ConversationIntelligenceInsights;
