/* eslint-disable camelcase */
export interface NavItemRange {
  start: moment.Moment;
  end: moment.Moment;
}

export interface NavItem {
  display: string;
  range: NavItemRange;
  id: string;
}

export interface NavItems {
  [key: string]: NavItem;
}

export interface Tag {
  name: string;
  count: number;
}

export interface ComputedTag {
  name: string;
  percentage: number;
}

export interface TopTags {
  total_conversations: number;
  tags: Tag[];
}

export interface TopicSentiments {
  positive: number;
  negative: number;
  neutral: number;
}

export interface Topic {
  label: string;
  total: number;
  sentiments: TopicSentiments;
}

export interface Category {
  name: string;
  topics: Topic[];
}

export interface TopTopics {
  total_conversations: number;
  categories: Category[];
}

export interface InsightsResult {
  top_tags: TopTags;
  top_topics: TopTopics;
}

export interface SubscriberMetrics {
  total_subscribers: number;
  matching_subscribers: number;
}

export interface ConversationMetrics {
  total_conversations: number;
  matching_conversations: number;
  active_conversations: number;
}

export type ConversationIntelligenceMetricsData = SubscriberMetrics &
  ConversationMetrics;

export enum ConversationIntelligenceStatus {
  NO_CONVERSATIONS = 'NO_CONVERSATIONS',
  PARTIAL_INSIGHTS = 'PARTIAL_INSIGHTS',
  COMPLETE_INSIGHTS = 'COMPLETE_INSIGHTS',
}
