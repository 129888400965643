import { BodyText, Heading, Icon, IconSet } from '@postscript/components';
import * as styles from '../styles';

const NoConversations = (): JSX.Element => (
  <styles.ZeroState>
    <styles.ZeroStateText>
      <Heading as="h2" size="small">
        Your conversations will appear here soon
      </Heading>
      <BodyText>
        Once SMS Sales is active, you&apos;ll see your conversations here for
        review. After 1,000 conversations, we&apos;ll start generating topics,
        sentiments, and other insights to help you understand your interactions
        better.
      </BodyText>
    </styles.ZeroStateText>
    <styles.ZeroStateCards>
      <styles.ZeroStateCard>
        <Icon
          color="var(--color-topic-primary)"
          component={IconSet.TwoWayConvo}
          size={48}
        />
        <Heading size="x-small">Review conversations</Heading>
        <BodyText size="small">
          Analyze interactions to uncover trends and refine your messaging.
        </BodyText>
      </styles.ZeroStateCard>
      <styles.ZeroStateCard>
        <Icon
          color="var(--color-topic-primary)"
          component={IconSet.PostscriptAi}
          size={48}
        />
        <Heading size="x-small">Get insights</Heading>
        <BodyText size="small">
          Unlock AI-driven topics to improve sales and personalize offers.
        </BodyText>
      </styles.ZeroStateCard>
      <styles.ZeroStateCard>
        <Icon
          color="var(--color-topic-primary)"
          component={IconSet.Users}
          size={48}
        />
        <Heading size="x-small">Build better segments</Heading>
        <BodyText size="small">
          Leverage zero-party data to understand and categorize customers for
          precise targeting.
        </BodyText>
      </styles.ZeroStateCard>
    </styles.ZeroStateCards>
  </styles.ZeroState>
);

export default NoConversations;
