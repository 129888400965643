/* eslint-disable-next-line react/no-array-index-key */
import { Banner, Switch } from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import { useSalesShopData } from 'components/sales/hooks/useShopData';
import { useUser } from 'controllers/contexts/user';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useIdentifyLogRocketUser } from '../../../hooks/useLogRocket';
import {
  Controls,
  HAS_SUMMARY,
} from '../components/ConversationIntelligenceControls';
import { VIEW_OPTIONS } from '../constants';
import { useConversationData } from '../hooks/useConversationIntelligenceData';
import { useConversationIntelligenceQueryParams } from '../hooks/useConversationIntelligenceQueryParams';
import { ConversationWithInsights } from '../hooks/useConversationSummaries';
import useSyncHistoryWithControls from '../hooks/useSyncHistoryWithControls';
import { useView } from '../hooks/useView';
import ConversationDrawer from './components/ConversationDrawer';
import ConversationIntelligenceInsights from './components/ConversationIntelligenceInsights';
import { ConversationIntelligenceMetrics } from './components/ConversationIntelligenceMetrics';
import ConversationSummaries from './components/ConversationSummaries';
import DateRangeTabGroup from './components/DateRangeTabGroup';
import InsightsPagination from './components/InsightsPagination';
import NoConversations from './components/NoConversations';
import SearchControls, {
  SearchControlsTitle,
} from './components/SearchControls';
import TrendChart from './components/TrendChart';
import { DATE_FORMAT, MAX_PAGE_SIZE } from './constants';
import * as styles from './styles';
import { ConversationIntelligenceStatus, NavItems } from './types';
import { dateRangeLabel, getIsMultiShop } from './utils';

const navItems: NavItems = {
  today: {
    display: 'Today',
    range: {
      start: moment().subtract(1, 'days'),
      end: moment(),
    },
    id: 'today',
  },
  last_7_days: {
    display: 'Last 7 Days',
    range: {
      start: moment().subtract(7, 'days'),
      end: moment(),
    },
    id: 'last_7_days',
  },
  last_30_days: {
    display: 'Last 30 Days',
    range: {
      start: moment().subtract(30, 'days'),
      end: moment(),
    },
    id: 'last_30_days',
  },
};

const ConversationIntelligence = (): JSX.Element => {
  const chatDrawerRef = useRef<HTMLDivElement>(null);
  const isMultiShop = getIsMultiShop();
  const user = useUser();
  const currentShopId = getShopId();
  const { view } = useView();

  const {
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    topicLabel,
    page,
    maxPageSize,
    filters,
    sortBy,
    dateRangeStart,
    dateRangeEnd,
  } = useConversationIntelligenceQueryParams(isMultiShop, currentShopId);

  const [subscriberId, setSubscriberId] = useState<number | undefined>(
    undefined,
  );

  const [controls, setControls] = useState<Controls>({
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    filters,
    topicLabel,
    sortBy,
    dateRangeEnd,
    dateRangeStart,
    page,
    maxPageSize,
  });
  const startDate = moment(dateRangeStart, DATE_FORMAT);
  const endDate = moment(dateRangeEnd, DATE_FORMAT);
  const numberOfDays = endDate.diff(startDate, 'days');
  const rangeIdMapping: { [key: number]: string } = {
    1: navItems.today.id,
    7: navItems.last_7_days.id,
    30: navItems.last_30_days.id,
  };
  const [selectedDateId, setSelectedDateId] = useState<string | null>(
    rangeIdMapping[numberOfDays] || null,
  );
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  useState(false);
  const { data: salesShopsData } = useSalesShopData();

  const [showNoInsightsBanner, setShowNoInsightsBanner] = useState(() => {
    return localStorage.getItem('insightsBannerDismissed') !== 'true';
  });

  const {
    metrics,
    isConversationsLoading,
    isMetricsLoading,
    isTopicLabelTrendsLoading,
    isInsightsLoading,
    summaries,
    topicLabelTrends,
    insights,
    conversationIntelligenceStatus,
    triggerJSONDownload,
  } = useConversationData(controls, isMultiShop, user);
  useSyncHistoryWithControls(controls);
  useIdentifyLogRocketUser('ssovkv/conversation-intelligence');

  const toggleHasSummaryFilter = () => {
    setControls((prevControls) => {
      const updatedFilters = prevControls.filters.includes(HAS_SUMMARY)
        ? prevControls.filters.filter((filter) => filter !== HAS_SUMMARY)
        : [...prevControls.filters, HAS_SUMMARY];

      return {
        ...prevControls,
        filters: updatedFilters,
      };
    });
  };

  /* ZERO STATE */
  const noConversations =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.NO_CONVERSATIONS;

  /* PARTIAL STATE BANNER */
  const hasInsights =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.PARTIAL_INSIGHTS;

  const handleDateRangeTabGroupClick = (
    formattedFrom: string,
    formattedTo: string,
    selectedId: string,
  ) => {
    setControls((prevControls) => ({
      ...prevControls,
      dateRangeStart: formattedFrom,
      dateRangeEnd: formattedTo,
    }));
    setSelectedDateId(selectedId);
  };

  const handleViewConversationClick = (
    conversation: ConversationWithInsights,
  ) => {
    setSubscriberId(conversation.subscriber?.id);
    setSelectedCardId(conversation.id);
  };

  const handleTopicLabelClick = (topicLabel: string) => {
    setControls((prevControls) => ({
      ...prevControls,
      topicLabel,
    }));
  };

  const handleTagClick = (tag: string) => {
    setControls((prevControls) => ({
      ...prevControls,
      tag,
    }));
  };

  const conversation = summaries.conversations.find(
    (conversation) => selectedCardId === conversation.id,
  );

  const shopData = salesShopsData?.shop_data.find(
    (shop) => shop.id === conversation?.shop?.id,
  );

  const isBubAI = view === VIEW_OPTIONS.AI_DEBUGGER;

  return (
    <>
      {!noConversations && (
        <styles.ActionsContainer>
          <DateRangeTabGroup
            navItems={navItems}
            selectedDateId={selectedDateId}
            handleClick={handleDateRangeTabGroupClick}
          />
        </styles.ActionsContainer>
      )}

      {noConversations ? (
        <NoConversations />
      ) : (
        <styles.CIColumns className={isBubAI ? 'multi-col' : 'single-col'}>
          <styles.MainColumn vertical>
            {!hasInsights && showNoInsightsBanner && (
              <Banner
                bodyText="Summaries, tags, and sentiments are available 48 hours after each conversation ends. Topic insights begin generating after 500-1000 conversations."
                heading="Your insights will be available here soon"
                onDismiss={() => {
                  setShowNoInsightsBanner(false);
                  localStorage.setItem('insightsBannerDismissed', 'true');
                }}
                variant="guidance"
              />
            )}
            <styles.TitleAndChart>
              <SearchControls
                controls={controls}
                setControls={setControls}
                isLoading={
                  isConversationsLoading ||
                  isInsightsLoading ||
                  isTopicLabelTrendsLoading
                }
                title={
                  <SearchControlsTitle
                    controls={controls}
                    dateLabel={dateRangeLabel({ selectedDateId, numberOfDays })}
                  />
                }
              />
              <TrendChart
                isTopicLabelTrendsLoading={isTopicLabelTrendsLoading}
                controls={controls}
                data={topicLabelTrends.trends}
                days={numberOfDays}
              />
            </styles.TitleAndChart>

            <styles.MetricsContainer>
              <ConversationIntelligenceMetrics
                onDownload={triggerJSONDownload}
                metrics={metrics}
                isLoading={isMetricsLoading}
                dateLabel={dateRangeLabel({ selectedDateId, numberOfDays })}
              />
            </styles.MetricsContainer>

            <styles.SummaryCardsAndTitle>
              <styles.CardHeadingRow>
                <styles.CardHeading size="x-small">
                  Highlights and summaries
                </styles.CardHeading>
                <Switch
                  checked={!controls.filters.includes(HAS_SUMMARY)}
                  onChange={toggleHasSummaryFilter}
                  fieldSize="small"
                  id="unsummarized-toggle"
                  label="Show unsummarized"
                />
              </styles.CardHeadingRow>

              <ConversationSummaries
                isLoading={isConversationsLoading}
                users={summaries.users}
                conversations={summaries.conversations}
                selectedCardId={selectedCardId}
                salesShopsData={salesShopsData}
                onViewConversationClick={handleViewConversationClick}
                onTopicLabelClick={handleTopicLabelClick}
              />

              <InsightsPagination
                isLoading={isConversationsLoading || isMetricsLoading}
                totalItems={metrics.matching_conversations}
                pageSize={MAX_PAGE_SIZE}
                page={controls.page}
                onPageChange={(page) =>
                  setControls((prevControls) => ({ ...prevControls, page }))
                }
              />
            </styles.SummaryCardsAndTitle>
          </styles.MainColumn>
          {view === VIEW_OPTIONS.AI_DEBUGGER && (
            <styles.RightColumn>
              <ConversationIntelligenceInsights
                insights={insights}
                metrics={metrics}
                numberOfDays={numberOfDays}
                isLoading={isInsightsLoading}
                shopName={user.user.shop_name}
                selectedTopicLabel={controls.topicLabel}
                selectedTag={controls.tag}
                handleTopicLabelClick={handleTopicLabelClick}
                handleTagClick={handleTagClick}
              />
            </styles.RightColumn>
          )}
        </styles.CIColumns>
      )}

      {conversation && (
        <styles.ChatContainer isOpen={!!selectedCardId} ref={chatDrawerRef}>
          <ConversationDrawer
            chatDrawerRef={chatDrawerRef}
            subscriberId={subscriberId}
            conversation={conversation}
            selectedCardId={selectedCardId}
            clearConversation={() => {
              setSubscriberId(undefined);
              setSelectedCardId(null);
            }}
            shopData={shopData}
          />
        </styles.ChatContainer>
      )}
    </>
  );
};

export default ConversationIntelligence;
