import {
  Icon,
  IconSet,
  Input,
  Layout,
  SelectMenu,
  SelectMenuCreatable,
  Textarea,
  Tooltip,
} from '@postscript/components';
import { Option } from '@postscript/components/dist/esm/components/form/SelectMenu/types';
import FormikField from 'components/billing/common/FormikField';
import { nameofFactory } from 'components/flowBuilder/types/util';
import { TRIGGER_TYPES } from 'components/Popup/constants';
import {
  CLOSE_ACTION_OPTIONS,
  getCountriesOptions,
} from 'components/Popup/constants/PopupSelectMenuOptions';
import { useShopifyPages } from 'components/Popup/hooks/usePopup';
import { PopupFormValues } from 'components/Popup/types';
import { getPhoneStepId } from 'components/Popup/utils/questionUtils';
import { usePopups } from 'controllers/contexts/popups';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import EditorFormDescription from '../EditorFormDescription';
import EditorFormGrid from '../EditorFormGrid';
import EditorFormItemWrapper from '../EditorFormItemWrapper';
import TriggerSettings from './TriggerSettings';

const TOOLTIP_ID = 'existingSubscriberSuccessMessage-tooltip';

export const NEW_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT =
  'You are now subscribed to text messages!';
export const EXISTING_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT =
  "You're already subscribed! Thanks for being part of the community.";

const SUBSCRIBER_COHORT_OPTIONS = [
  {
    label: 'Show to known subscribers and unknown visitors',
    value: 'EVERYONE',
  },
  {
    label: 'Show only to known subscribers',
    value: 'EXISTING_SMS_SUBSCRIBERS',
  },
  { label: 'Show only to unknown visitors', value: 'NEW_SMS_USERS' },
];

const nameof = nameofFactory<PopupFormValues>();

const getPagesPlaceholder = (
  isLoading: boolean,
  isCustomTrigger: boolean,
): string | null => {
  if (isLoading) {
    return 'loading...';
  }

  if (isCustomTrigger) {
    return 'Set via code when using custom popup trigger';
  }

  return null;
};

const CustomTriggerTooltip = () => (
  <>
    When a popup uses a custom trigger,
    <br />
    the included and excluded pages are
    <br />
    defined by where the code for
    <br />
    the popup is embedded.
  </>
);

const StyledTextAreaWrapper = styled.div`
  grid-column: 1 / -1;

  label > abbr {
    display: none;
  }
`;

const StyledDiv = styled.div`
  grid-column: 1 / -1;
`;

const StyledLabelInner = styled.div`
  display: flex;
  align-items: center;

  > abbr {
    color: var(--highlight-color);
    display: inline-block;
    padding-left: calc(var(--spacing-1) / 2);
    text-decoration: none;
  }
`;

const BehaviorTabPanel = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<PopupFormValues>();
  const {
    closeAction,
    collectionAttributes,
    excludedPages,
    includedPages,
    pages,
    trigger,
  } = values;
  const { updateIsPreviewCollapsed } = usePopups();
  const { data: shopifyPages, isLoading } = useShopifyPages();
  const shopifyPageOptions = shopifyPages?.map((i: string) => ({
    value: i,
    label: i,
  }));
  const setCollectionOrder = (phoneFirst: boolean) => {
    let collectionAttributesArray = collectionAttributes.split(',');
    const phoneStepId = getPhoneStepId(collectionAttributes, pages ?? []);
    collectionAttributesArray = collectionAttributesArray.filter(
      (attribute) => attribute !== phoneStepId,
    );

    if (phoneStepId) {
      // eslint-disable-next-line chai-friendly/no-unused-expressions
      phoneFirst
        ? collectionAttributesArray.unshift(phoneStepId)
        : collectionAttributesArray.push(phoneStepId);
    }

    return collectionAttributesArray.toString();
  };
  const isCustomTrigger = trigger === TRIGGER_TYPES.CUSTOM;
  const pagesInputPlaceholder = getPagesPlaceholder(isLoading, isCustomTrigger);

  const STEP_ORDER_OPTIONS = [
    { label: 'Phone First', value: setCollectionOrder(true) },
    { label: 'Phone Last', value: setCollectionOrder(false) },
  ];

  return (
    <EditorFormGrid>
      <EditorFormDescription>
        Control when, where, and how your popup behaves on your site.
      </EditorFormDescription>
      <FormikField
        name={nameof('collectionAttributes')}
        label="Step order"
        as={SelectMenu}
        options={STEP_ORDER_OPTIONS}
        value={STEP_ORDER_OPTIONS.find(
          (o) => o.value === values.collectionAttributes,
        )}
        onChange={({ value }: { value: string }) => {
          setFieldValue(`collectionAttributes`, value);
        }}
        isClearable={false}
        disabled={collectionAttributes.split(',').length < 2}
      />

      <div data-tip data-for="countries-allowed-tooltip">
        <FormikField
          name={nameof('countriesAllowed')}
          label="Countries"
          as={SelectMenu}
          options={getCountriesOptions()}
          placeholder={isCustomTrigger ? 'Set via code' : null}
          value={
            isCustomTrigger
              ? null
              : getCountriesOptions().find(
                  (o) => o.value === values.countriesAllowed,
                )
          }
          onChange={({ value }: { value: string }) => {
            setFieldValue(`countriesAllowed`, value);
          }}
          isClearable={false}
          disabled={isCustomTrigger}
        />
      </div>
      {isCustomTrigger && (
        <Tooltip id="countries-allowed-tooltip" place="top">
          When a popup uses a custom trigger, the allowed
          <br />
          countries must be controlled via code.
        </Tooltip>
      )}
      <TriggerSettings />
      <FormikField
        label={
          <Layout valign="center">
            Close action
            <Icon
              component={IconSet.Information}
              data-tip
              data-for="close-action-info-icon"
              size={20}
            />
            <Tooltip id="close-action-info-icon" place="right">
              Choose whether the popup should be minimized inside a floating
              <br />
              bubble, or permanently closed when someone dismisses it.
            </Tooltip>
          </Layout>
        }
        name={nameof('closeAction')}
        as={SelectMenu}
        options={CLOSE_ACTION_OPTIONS}
        value={CLOSE_ACTION_OPTIONS.find((o) => o.value === values.closeAction)}
        onChange={({ value }: { value: string }) => {
          if (value === 'Exit') {
            updateIsPreviewCollapsed(false);
          }

          setFieldValue(`closeAction`, value);
        }}
        isClearable={false}
      />
      {closeAction !== 'Exit' && (
        <FormikField
          as={Input}
          data-change-preview
          label="Close action text"
          name={nameof('bubbleText')}
          type="text"
        />
      )}
      <EditorFormItemWrapper
        isFullwidth
        label={
          <Layout valign="center">
            Include pages
            <Icon
              component={IconSet.Information}
              data-tip
              data-for="include-pages-info-icon"
              size={20}
            />
            <Tooltip id="include-pages-info-icon" place="right">
              {isCustomTrigger ? (
                <CustomTriggerTooltip />
              ) : (
                <>
                  The popup will be displayed on these pages. Leaving this blank
                  <br />
                  will allow the popup to display on every page.
                </>
              )}
            </Tooltip>
          </Layout>
        }
        onChange={(value: Option[]) =>
          setFieldValue(nameof('includedPages'), value)
        }
        value={isCustomTrigger ? null : includedPages}
        name={nameof('includedPages')}
      >
        <SelectMenuCreatable
          options={shopifyPageOptions}
          isMulti
          disabled={isLoading || isCustomTrigger}
          placeholder={pagesInputPlaceholder}
        />
      </EditorFormItemWrapper>
      <EditorFormItemWrapper
        isFullwidth
        label={
          <Layout valign="center">
            Exclude pages
            <Icon
              component={IconSet.Information}
              data-tip
              data-for="exclude-pages-info-icon"
              size={20}
            />
            <Tooltip id="exclude-pages-info-icon" place="right">
              {isCustomTrigger ? (
                <CustomTriggerTooltip />
              ) : (
                'The popup will be hidden on these pages.'
              )}
            </Tooltip>
          </Layout>
        }
        onChange={(value: Option[]) =>
          setFieldValue(nameof('excludedPages'), value)
        }
        value={isCustomTrigger ? null : excludedPages}
        name={nameof('excludedPages')}
      >
        <SelectMenuCreatable
          options={shopifyPageOptions}
          isMulti
          disabled={isLoading || isCustomTrigger}
          placeholder={pagesInputPlaceholder}
        />
      </EditorFormItemWrapper>
      <StyledDiv>
        <FormikField
          name={nameof('subscriberCohort')}
          label="Subscriber status"
          as={SelectMenu}
          options={SUBSCRIBER_COHORT_OPTIONS}
          value={
            SUBSCRIBER_COHORT_OPTIONS.find(
              (o) => o.value === values.subscriberCohort,
            ) ||
            SUBSCRIBER_COHORT_OPTIONS.find((o) => o.value === 'NEW_SMS_USERS')
          }
          onChange={({ value }: { value: string }) => {
            setFieldValue(nameof(`subscriberCohort`), value);
          }}
          isClearable={false}
        />
      </StyledDiv>
      <StyledDiv>
        <FormikField
          name={nameof('newSubscriberSuccessMessage')}
          label="New subscriber success message"
          as={Textarea}
          value={
            values.newSubscriberSuccessMessage ??
            NEW_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT
          }
          message="Limit 100 characters"
          required
        />
      </StyledDiv>
      <StyledTextAreaWrapper>
        <FormikField
          name={nameof('existingSubscriberSuccessMessage')}
          label={
            <StyledLabelInner>
              Existing subscriber success message
              <abbr
                aria-label="This field is required"
                title="This field is required"
              >
                *
              </abbr>
              <Icon
                aria-label="More information about custom success message for new or existing subscribers"
                component={IconSet.Information}
                data-for={TOOLTIP_ID}
                data-tip
                size="medium"
              />
              <Tooltip id={TOOLTIP_ID}>
                An existing subscriber may still see a popup even if it is only
                targeting unknown visitors, <br />
                based on if a visitor is cookied or browsing anonymously. <br />
                If an existing subscriber completes the signup form, they will
                see this message.
              </Tooltip>
            </StyledLabelInner>
          }
          required
          as={Textarea}
          value={
            values.existingSubscriberSuccessMessage ??
            EXISTING_SUBSCRIBER_SUCCESS_MESSAGE_DEFAULT
          }
          message="Limit 100 characters"
        />
      </StyledTextAreaWrapper>
    </EditorFormGrid>
  );
};

export default BehaviorTabPanel;
