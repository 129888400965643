import { BodyText, Icon, IconSet } from '@postscript/components';
import moment from 'moment';
import { ConversationWithInsights } from '../../../hooks/useConversationSummaries';
import * as styles from '../../styles';

interface Props {
  conversation: ConversationWithInsights;
}

const formatDate = (inputDate: string): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateObj = new Date(inputDate);
  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};

const getStatusColor = (conversation: ConversationWithInsights) => {
  if (conversation.active) {
    return 'var(--green-2)';
  }

  if (
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    return 'var(--blue-2)';
  }

  if (
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return 'var(--yellow-2)';
  }
  return 'var(--gray-3)';
};

const getStatusBody = (conversation: ConversationWithInsights) => {
  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment().add(1, 'hour')) &&
    moment(conversation.expiry_at).isSameOrBefore(moment().add(48, 'hour'))
  ) {
    return `Happening now`;
  }

  if (
    conversation.active &&
    moment(conversation.updated_at).isAfter(moment().subtract(10, 'minutes'))
  ) {
    const updatedAt = moment.utc(conversation.updated_at);
    const duration = moment.duration(moment().diff(updatedAt));
    const minutesAgo = Math.floor(duration.asMinutes());
    return `${minutesAgo} min ago`;
  }

  if (
    conversation.active &&
    moment(conversation.expiry_at).isSameOrAfter(moment()) &&
    moment(conversation.expiry_at).isBefore(moment().add(1, 'hour'))
  ) {
    return `Expiring soon`;
  }
  return conversation.closed_at === null
    ? `Inactive`
    : `Inactive ${formatDate(conversation.closed_at)}`;
};

const ActivityBody = ({ conversation }: Props): JSX.Element => {
  return (
    <>
      <Icon
        color={getStatusColor(conversation)}
        component={IconSet.Disc}
        size={18}
      />
      <BodyText size="x-small">{getStatusBody(conversation)}</BodyText>
    </>
  );
};

const EngagementBody = ({ conversation }: Props): JSX.Element => {
  const messageCount = conversation.messages.length;
  const unsubscribed =
    conversation.outcomes && conversation.outcomes[0]
      ? conversation.outcomes[0]?.type === 'subscriber/opt_out'
      : false;
  let engagementIconColor = 'var(--blue-5)';
  let engagementIcon = IconSet.TwoWayConvo;
  let engagementText = `${messageCount} message${messageCount > 1 ? 's' : ''}`;

  if (messageCount >= 6 && !unsubscribed) {
    engagementIconColor = 'var(--orange-4)';
    engagementIcon = IconSet.Bolt;
    engagementText = `Engaged (${engagementText})`;
  }
  if (unsubscribed) {
    engagementIconColor = 'var(--red-core)';
    engagementIcon = IconSet.Forbid;
    engagementText = `Unsubscribed (${engagementText})`;
  }

  return (
    <>
      <Icon color={engagementIconColor} component={engagementIcon} size={18} />
      <BodyText size="x-small">{engagementText}</BodyText>
    </>
  );
};

const LogLine = ({ conversation }: Props): JSX.Element => {
  return (
    <styles.LogLine>
      <styles.LogLineItem>
        <ActivityBody conversation={conversation} />
      </styles.LogLineItem>
      <styles.LogLineItem>
        <EngagementBody conversation={conversation} />
      </styles.LogLineItem>
    </styles.LogLine>
  );
};

export default LogLine;
