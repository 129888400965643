export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const MAX_PAGE_SIZE = 20;
export const CIRCLE_ICON_SIZE = 14;
export const MAX_TOPIC_LABEL_RANK = 1;
export const TOPIC_LABEL_VERSION = '0';
export const TOPICS_TAB_PANEL_ID = 'topics-tab-panel';
export const TAGS_TAB_PANEL_ID = 'tags-tab-panel';
export const MAX_TAGS_TO_DISPLAY = 50;
export const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
