import {
  getMessageListItemsForSubscriber,
  MessageListItem,
} from 'components/responses/utils/messages';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useView } from '../hooks/useView';
import { MessageThreadProps } from './MessageThread';

/* MessageList component for responses AND SMS Sales */

const StyledUL = styled.ul<{ initialized: boolean }>`
  list-style: none;
  height: 100%;
  position: relative;

  & > li {
    margin-top: var(--spacing-2);
    position: relative;
    z-index: 2;

    &:empty:not(:last-child) {
      display: none;
    }

    &.message-type-timestamp + .message-type-timestamp {
      margin-top: var(--spacing-1);
    }

    &.message-type-system:not(:empty) + .message-type-system:not(:empty) {
      margin-top: 0;
    }

    &.message-type-incoming {
      & + .message-type-incoming {
        margin-top: var(--spacing-1);
      }

      &:has(+ .message-type-incoming) {
        .message-bubble {
          border-bottom-left-radius: var(--border-radius-medium);
        }
        .message-avatar {
          display: none;
        }
      }
    }

    &.message-type-outgoing {
      & + .message-type-outgoing {
        margin-top: var(--spacing-1);
      }

      &:has(+ .message-type-outgoing) {
        .message-bubble {
          border-bottom-right-radius: var(--border-radius-medium);
        }
        .message-avatar {
          display: none;
        }
      }
    }

    &.message-type-bub {
      & + .message-type-bub {
        margin-top: var(--spacing-1);
      }

      &:has(+ .message-type-bub) {
        .message-bubble {
          border-bottom-right-radius: var(--border-radius-medium);
        }
        .message-avatar {
          display: none;
        }
      }
    }
  }
`;

export const MessageList = ({
  subscriber,
  messageThreadRef,
}: MessageThreadProps): JSX.Element => {
  const { view } = useView();
  const messageItems: MessageListItem[] = useMemo(
    () => getMessageListItemsForSubscriber(subscriber, view),
    [subscriber, view],
  );

  const getMessageType = (props: any) => {
    if (!props) return;
    let type = 'system';
    if (props.group?.type === 'message-group') {
      type = 'timestamp';
    }
    if (props.message) {
      if (props.message.direction) {
        type = props.message.direction;
      }
      if (
        props.message.direction === 'outgoing' &&
        (!props.message.user_first_name || props.message.user_first_name === '')
      ) {
        type = 'bub';
      }
    }
    return type;
  };

  const shouldShowMessage = (item: MessageListItem) => {
    const isSystemMessage = getMessageType(item.el?.props) === 'system';
    const hideSystemMessages =
      localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ===
      'Merchant View';
    return !(isSystemMessage && hideSystemMessages);
  };

  return (
    <StyledUL
      initialized={Boolean(subscriber)}
      ref={messageThreadRef}
      data-testid="message-list"
    >
      {messageItems.map(
        (item) =>
          shouldShowMessage(item) && (
            <li
              className={`message-type-${getMessageType(item.el?.props)}`}
              id={`message-${item.key}`}
              key={item.key}
            >
              {item.el}
            </li>
          ),
      )}
    </StyledUL>
  );
};
