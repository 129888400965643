/* eslint-disable no-alert */
import {
  Badge,
  BodyText,
  Button,
  ButtonAnchor,
  EmptyState,
  Heading,
  Icon,
  IconSet,
  Layout,
} from '@postscript/components';
import { api } from 'controllers/network/apiClient';
import { useState } from 'react';
import ConversationCardNoSummaryBody from '../../components/ConversationCardNoSummaryBody';
import { VIEW_OPTIONS } from '../../constants';
import {
  ConversationIntelligenceUser,
  ConversationWithInsights,
} from '../../hooks/useConversationSummaries';
import { useView } from '../../hooks/useView';
import { SalesShopsData } from '../../types';
import {
  CIRCLE_ICON_SIZE,
  MAX_TOPIC_LABEL_RANK,
  TOPIC_LABEL_VERSION,
} from '../constants';
import * as styles from '../styles';
import { formatTopicLabel, getBadgeStyles, getIsMultiShop } from '../utils';
import { formatPhoneNumber } from '../utils/phone';
import LogLine from './LogLine';

const generateInsights = async (conversation: ConversationWithInsights) => {
  const response = await api.post(
    `/v2/convert/conversations/${conversation.id}/generate-insights`,
  );
  if (response.ok) {
    window.location.reload();
  } else {
    alert(response.message);
  }
};

const getIdentifier = (conversation: ConversationWithInsights) => {
  const formattedName =
    conversation.subscriber?.first_name && conversation.subscriber?.last_name
      ? `${
          conversation.subscriber.first_name
        } ${conversation.subscriber.last_name[0].toUpperCase()}`
      : '';

  const formattedPhoneNumber =
    conversation.subscriber?.phone_number &&
    formatPhoneNumber(conversation.subscriber?.phone_number);

  const identifier =
    (formattedName || formattedPhoneNumber) &&
    `${formattedName || ''}${formattedName && formattedPhoneNumber && ' · '}${
      formattedPhoneNumber || ''
    }`;

  return identifier;
};

interface ConversationTagsProps {
  tags: string[];
}

const ConversationTags = ({ tags }: ConversationTagsProps): JSX.Element => {
  let counter = 0;
  return (
    <>
      {tags.map((tag) => {
        const key = `${tag}-${counter}`;
        counter += 1;
        if (tag.startsWith('assistant_thread_id_')) {
          const threadId = tag.replace('assistant_thread_id_', '');
          const link = `https://platform.openai.com/threads/${threadId}`;
          return (
            <BodyText key={key} size="small">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {tag}
              </a>
            </BodyText>
          );
        }
        return (
          <BodyText key={key} size="small">
            {tag}
          </BodyText>
        );
      })}
    </>
  );
};

interface ConversationCardProps {
  conversation: ConversationWithInsights;
  users?: ConversationIntelligenceUser[];
  selectedCardId: string | null;
  onViewConversationClick: (conversation: ConversationWithInsights) => void;
  onTopicLabelClick: (topicLabel: string) => void;
  salesShopsData?: SalesShopsData;
}

const ConversationCard = ({
  conversation,
  users = [],
  selectedCardId,
  onViewConversationClick,
  onTopicLabelClick,
  salesShopsData,
}: ConversationCardProps): JSX.Element => {
  const { view } = useView();
  const [activeCardTab, setActiveCardTab] = useState<'merchant' | 'debug'>(
    'merchant',
  );
  const isMultiShop = getIsMultiShop();
  // Using first insight in list (expected to be the only one)
  const insight =
    conversation.insights && conversation.insights.length > 0
      ? conversation.insights[0]
      : undefined;

  const converted =
    conversation.outcomes && conversation.outcomes[0]
      ? conversation.outcomes[0]?.type === 'order/create'
      : false;

  const identifier = getIdentifier(conversation);

  const participatingUsers: { [id: number]: ConversationIntelligenceUser } = {};

  if (users) {
    users.forEach((user) => {
      participatingUsers[user.id] = user;
    });
  }

  const participantNamesSet = new Set<string>();

  conversation.messages
    .map((message) => message.user_id)
    .filter((userId) => userId !== null)
    .forEach((userId) => {
      if (!userId) {
        return;
      }

      if (
        !participatingUsers[userId] ||
        !participatingUsers[userId].first_name ||
        !participatingUsers[userId].last_name
      ) {
        return;
      }
      const { first_name: firstName, last_name: lastName } =
        participatingUsers[userId];
      const name = `${firstName} ${
        lastName && lastName.length > 0 && lastName[0]
      }.`;
      participantNamesSet.add(name);
    });

  const participantNames: string[] = Array.from(participantNamesSet);

  // The following approach makes sure that topic labels are rendered
  // at the top and tags are rendered next only if they are not an existing topic label
  const topicLabelsAndTags: Set<string> = new Set();
  if (conversation.insights && conversation.insights[0]) {
    conversation.insights[0].topic_labels.forEach((topicLabel) => {
      if (
        'rank' in topicLabel &&
        topicLabel.rank <= MAX_TOPIC_LABEL_RANK &&
        topicLabel.version === TOPIC_LABEL_VERSION
      ) {
        topicLabelsAndTags.add(topicLabel.label);
      }
    });

    conversation.insights[0].labels.forEach((label) => {
      topicLabelsAndTags.add(label);
    });
  }

  const hasInsights = topicLabelsAndTags.size > 0;
  const sentimentIcons: {
    [sentiment: string]: { icon: JSX.Element; label: string };
  } = {
    positive: {
      icon: (
        <styles.Circle color="var(--color-sentiment-icon-positive)">
          <Icon
            color="var(--color-sentiment-text-positive)"
            component={IconSet.FaceSmile}
            size={CIRCLE_ICON_SIZE}
          />
        </styles.Circle>
      ),
      label: 'Positive',
    },
    neutral: {
      icon: (
        <styles.Circle color="var(--color-sentiment-icon-neutral)">
          <Icon
            color="var(--color-sentiment-text-neutral)"
            component={IconSet.FaceStraight}
            size={CIRCLE_ICON_SIZE}
          />
        </styles.Circle>
      ),
      label: 'Neutral',
    },
    negative: {
      icon: (
        <styles.Circle color="var(--color-sentiment-icon-negative)">
          <Icon
            color="var(--color-sentiment-text-negative)"
            component={IconSet.FaceSad}
            size={CIRCLE_ICON_SIZE}
          />
        </styles.Circle>
      ),
      label: 'Negative',
    },
    none: {
      icon: (
        <styles.Circle color="var(--color-sentiment-icon-neutral)">
          <Icon
            color="var(--color-sentiment-text-neutral)"
            component={IconSet.Forbid2}
            size={CIRCLE_ICON_SIZE}
          />
        </styles.Circle>
      ),
      label: 'No sentiment available',
    },
  };

  const cardContent =
    insight && insight.summary ? (
      <BodyText style={{ flex: '1' }}>{insight.summary}</BodyText>
    ) : (
      <ConversationCardNoSummaryBody
        conversation={conversation}
        participatingUsers={participatingUsers}
        isMultiShop={isMultiShop}
        truncate
      />
    );

  const handleCardTabClick = (tabId: 'merchant' | 'debug') => {
    setActiveCardTab(tabId);
  };

  const shopData = salesShopsData?.shop_data.find(
    (shop) => shop.id === conversation.shop?.id,
  );

  const isBubAI = view === VIEW_OPTIONS.AI_DEBUGGER;

  return (
    <styles.SummaryCard
      className={`${selectedCardId === conversation.id ? 'active' : ''} ${
        !hasInsights && 'no-insights'
      }`}
      key={conversation.id}
      data-testid="summary-card"
    >
      <div>
        <styles.SumaryCardLeftCol>
          <Layout vertical gap="var(--spacing-2)">
            {/* Badge */}
            {isMultiShop && (
              <Badge style={getBadgeStyles(shopData)}>
                {conversation.shop?.name || 'Unknown Shop'}
              </Badge>
            )}

            {/* Header */}
            <styles.SummaryHeader>
              {identifier && <Heading size="x-small">{identifier}</Heading>}
              <LogLine conversation={conversation} />
            </styles.SummaryHeader>
          </Layout>

          {/* Debug Panel */}
          {isBubAI && (
            <>
              <styles.CardTabGroup
                description="Choose a view level"
                id={`summary-card-view-${conversation.id}`}
                placement="container"
              >
                <button
                  data-selected={activeCardTab === 'merchant'}
                  data-tabpanel={`card-insights-${conversation.id}`}
                  id={`card-insights-${conversation.id}-tab`}
                  onClick={() => handleCardTabClick('merchant')}
                  type="button"
                >
                  Merchant View
                </button>
                <button
                  data-selected={activeCardTab === 'debug'}
                  data-tabpanel={`card-debug-${conversation.id}`}
                  id={`card-debug-${conversation.id}-tab`}
                  onClick={() => handleCardTabClick('debug')}
                  type="button"
                >
                  Debug View
                </button>
              </styles.CardTabGroup>

              <styles.CardTabPanel
                id={`card-insights-${conversation.id}`}
                selected={activeCardTab === 'debug'}
              >
                <styles.DebugContents>
                  {conversation.tags && (
                    <>
                      <BodyText size="small">Conversation Tags: </BodyText>
                      <ConversationTags tags={conversation.tags} />
                    </>
                  )}
                </styles.DebugContents>
              </styles.CardTabPanel>
            </>
          )}

          {/* Conversation / Summary */}
          {isBubAI ? (
            <styles.CardTabPanel
              id={`card-insights-${conversation.id}`}
              selected={activeCardTab === 'merchant'}
            >
              {cardContent}
            </styles.CardTabPanel>
          ) : (
            cardContent
          )}

          {/* Buttons */}
          <styles.CardButtons>
            <Button
              className="view-conversation-button"
              icon={IconSet.TwoWayConvo}
              onClick={() => onViewConversationClick(conversation)}
              monochrome
              variant="text"
              size="small"
              data-testid="view-conversation"
            >
              View Conversation
            </Button>
            <ButtonAnchor
              className="subscriber-details-button"
              icon={IconSet.User}
              href={`${window.location.origin}/subscribers/${conversation.subscriber?.id}`}
              target="_blank"
              monochrome
              variant="text"
              size="small"
            >
              Subscriber Detail
            </ButtonAnchor>
          </styles.CardButtons>
        </styles.SumaryCardLeftCol>

        <styles.SumaryCardRightCol>
          <styles.Hashtags>
            {/* Agent */}
            {isMultiShop && participantNames.length > 0 && (
              <styles.CardSidebarItem>
                <styles.Circle color="var(--highlight-color)">
                  <Icon
                    color="var(--white)"
                    component={IconSet.Bub}
                    size={CIRCLE_ICON_SIZE}
                  />
                </styles.Circle>
                <span>{participantNames.join(',')}</span>
              </styles.CardSidebarItem>
            )}

            {/* Order */}
            {hasInsights && (
              <styles.CardSidebarItem>
                <styles.Circle
                  color={converted ? 'var(--green-2)' : 'var(--gray-3)'}
                >
                  <Icon
                    color={converted ? 'var(--green-8)' : 'var(--black)'}
                    component={IconSet.Dollar2}
                    size={CIRCLE_ICON_SIZE}
                  />
                </styles.Circle>
                <span>{converted ? 'Converted' : 'Did not convert'}</span>
              </styles.CardSidebarItem>
            )}

            {/* Sentiment */}
            {hasInsights &&
              (insight && insight.sentiment ? (
                <styles.CardSidebarItem>
                  {sentimentIcons[insight.sentiment.toLowerCase()].icon}
                  <span>
                    {sentimentIcons[insight.sentiment.toLowerCase()].label}
                  </span>
                </styles.CardSidebarItem>
              ) : (
                <styles.CardSidebarItem>
                  {sentimentIcons.none.icon}
                  <span>{sentimentIcons.none.label}</span>
                </styles.CardSidebarItem>
              ))}

            {/* Tags and Topics */}
            {hasInsights ? (
              Array.from(topicLabelsAndTags).map((value) => {
                return (
                  <styles.Topic
                    key={value}
                    tabIndex={0}
                    onClick={() => onTopicLabelClick(value)}
                    role="button"
                    href="#"
                  >
                    <styles.TopicIcon
                      component={IconSet.Hashtag}
                      color="var(--blue-5)"
                    />

                    {formatTopicLabel(value)}
                  </styles.Topic>
                );
              })
            ) : (
              <styles.CardEmptyState
                description="Insights not yet available"
                size="small"
                primaryAction={
                  isBubAI
                    ? {
                        text: 'Generate Insights',
                        onClick: () => generateInsights(conversation),
                        monochrome: true,
                        size: 'small',
                        variant: 'secondary',
                      }
                    : undefined
                }
              />
            )}
          </styles.Hashtags>
        </styles.SumaryCardRightCol>
      </div>
    </styles.SummaryCard>
  );
};

interface Props {
  isLoading: boolean;
  conversations: ConversationWithInsights[];
  users?: ConversationIntelligenceUser[];
  selectedCardId: string | null;
  salesShopsData?: SalesShopsData;
  onViewConversationClick: (conversation: ConversationWithInsights) => void;
  onTopicLabelClick: (topicLabel: string) => void;
}

const ConversationSummaries = ({
  isLoading,
  conversations,
  users,
  selectedCardId,
  salesShopsData,
  onViewConversationClick,
  onTopicLabelClick,
}: Props): JSX.Element => {
  if (isLoading) {
    return (
      <>
        <styles.SkeletonCardTall />
        <styles.SkeletonCardTall />
        <styles.SkeletonCardTall />
      </>
    );
  }

  if (!conversations.length) {
    return (
      <styles.VoidCard>
        <EmptyState
          size="medium"
          heading="No conversations available"
          description="Please refine your filters or return to the default view"
        />
      </styles.VoidCard>
    );
  }

  return (
    <>
      {conversations.map((conversation) => (
        <ConversationCard
          key={conversation.id}
          conversation={conversation}
          users={users}
          selectedCardId={selectedCardId}
          salesShopsData={salesShopsData}
          onViewConversationClick={onViewConversationClick}
          onTopicLabelClick={onTopicLabelClick}
        />
      ))}
    </>
  );
};

export default ConversationSummaries;
