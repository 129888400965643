import ViewContext from 'components/sales/hooks/useView';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useUser } from 'controllers/contexts/user';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getMaxViewLevelForUser } from '../utils/roles';
import ToggleAgentActionRecording from './ToggleAgentActionRecording';
import { ViewSelector } from './ViewSelector';

interface MultiViewDebugWrapperProps {
  children: React.ReactNode;
  withRecording?: boolean;
}

const AIDebuggerToolsWrapper = styled.div`
  position: absolute;
  top: 13px;
  z-index: 101;
  right: 54px;
  display: flex;
  align-items: center;
`;

export const MultiViewDebugWrapper: React.FC<MultiViewDebugWrapperProps> = ({
  children,
  withRecording,
}) => {
  const { user } = useUser();
  const [view, setView] = useState(
    localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ||
      getMaxViewLevelForUser(user),
  );

  return (
    <ViewContext.Provider value={{ view, setView }}>
      <AIDebuggerToolsWrapper>
        {withRecording && <ToggleAgentActionRecording />}
        <div style={{ marginRight: 'var(--spacing-2)' }}>
          <ViewSelector />
        </div>
      </AIDebuggerToolsWrapper>
      {children}
    </ViewContext.Provider>
  );
};
