import { TabGroup } from '@postscript/components';
import { DATE_FORMAT } from '../constants';
import { NavItems } from '../types';

interface IProps {
  navItems: NavItems;
  selectedDateId: string | null;
  handleClick: (
    formattedFrom: string,
    formattedTo: string,
    selectedDateId: string,
  ) => void;
}

const DateRangeTabGroup = ({
  navItems,
  selectedDateId,
  handleClick,
}: IProps) => {
  return (
    <TabGroup description="Select a date range" id="insights-date-tabgroup">
      {Object.keys(navItems).map((item) => {
        const { start, end } = navItems[item].range;

        return (
          <button
            data-selected={navItems[item].id === selectedDateId}
            key={item}
            onClick={() => {
              const formattedFrom = start.format(DATE_FORMAT);
              const formattedTo = end.format(DATE_FORMAT);
              handleClick(formattedFrom, formattedTo, navItems[item].id);
            }}
            data-cy={`insights-date-nav-button-${navItems[item].display
              .replace(/\s/g, '')
              .toLowerCase()}`}
            type="button"
          >
            {navItems[item].display}
          </button>
        );
      })}
    </TabGroup>
  );
};

export default DateRangeTabGroup;
