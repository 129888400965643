import { useCallback, useState } from 'react';
import { ConversationIntelligenceMetricsData } from '../pages/types';

export const useConversationIntelligenceMetrics = () => {
  const [metrics, setMetrics] = useState<ConversationIntelligenceMetricsData>({
    total_conversations: 0,
    matching_conversations: 0,
    active_conversations: 0,
    total_subscribers: 0,
    matching_subscribers: 0,
  });

  const updateMetrics = useCallback(
    (updates: Partial<ConversationIntelligenceMetricsData>) => {
      // Use functional updates to avoid overwriting state during async updates
      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        ...updates,
      }));
    },
    [],
  );

  return { metrics, updateMetrics };
};
