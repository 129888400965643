import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ConversationalInsights from './pages/ConversationalInsights';
import Performance from './pages/Performance';
import { SmsSalesConversationIntelligenceView } from './pages/views/SmsSalesConversationIntelligence';

const Routes = (): JSX.Element | null => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/performance`} component={Performance} />
      <Route path={`${url}/conversations`} component={ConversationalInsights} />
      <Route
        path={`${url}/conversation-intelligence`}
        component={SmsSalesConversationIntelligenceView}
      />
      <Redirect to={`${url}/performance`} />
    </Switch>
  );
};

export default Routes;
