import { saveImageFile } from 'components/flowBuilder/utils/messages';
import { useState } from 'react';

const IMAGE_MIME_TYPE_PREFIX = 'image/';

// Note: Keeping the number of bytes in decimal for consistency with OS storage practices
const DEFAULT_MAX_IMAGE_FILE_SIZE = 1000000; // 1 MB
export const RECOMMENDED_IMAGE_BYTES_THRESHOLD = 500000; // 500 KB
export const DEFAULT_MAX_IMAGE_FILE_SIZE_ERROR =
  'File size must be less than 1 MB (we recommend less than 500 KB for optimal sizing & resolution)';

interface Options {
  maxImageSize?: number;
  maxImageSizeError?: string;
  imageUploadUrl?: string;
}

const validateImageFile = (file: File, options?: Options) => {
  if (!file.type.startsWith(IMAGE_MIME_TYPE_PREFIX)) {
    throw new Error('File type is not allowed');
  }
  if (file.size > (options?.maxImageSize || DEFAULT_MAX_IMAGE_FILE_SIZE)) {
    throw new Error(
      options?.maxImageSizeError || DEFAULT_MAX_IMAGE_FILE_SIZE_ERROR,
    );
  }
};

const useImageFileUpload = (
  options?: Options,
): [boolean, (file: File) => Promise<string>, (file: File) => void] => {
  const [isUploadingImageFile, setIsUploadingImageFile] = useState(false);

  const uploadImageFile = async (file: File) => {
    try {
      validateImageFile(file, options);
      setIsUploadingImageFile(true);
      const mediaUrl = await saveImageFile(file, options?.imageUploadUrl);
      setIsUploadingImageFile(false);
      return mediaUrl;
    } catch (err: unknown) {
      setIsUploadingImageFile(false);
      throw err;
    }
  };

  return [isUploadingImageFile, uploadImageFile, validateImageFile];
};

export default useImageFileUpload;
