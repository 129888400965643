/* eslint-disable camelcase */
import moment, { Moment } from 'moment';
import { Bar } from 'react-chartjs-2';
import {
  Controls,
  FILTER_LABEL_LOOKUP,
  HAS_SUMMARY,
} from '../../../components/ConversationIntelligenceControls';
import { TopicLabelTrendGroup } from '../../../types';
import * as styles from '../../styles';

type TrendData = {
  group: string;
  total_conversations: number;
  total_conversations_for_topic_label: number;
};

interface Props {
  isTopicLabelTrendsLoading: boolean;
  controls: Controls;
  data: TopicLabelTrendGroup[];
  days: number;
}

const shouldRenderLineChart = (controls: Controls) =>
  controls.topicLabel ||
  controls.tag ||
  controls.searchText ||
  (controls.filters.length === 1 && controls.filters[0] !== HAS_SUMMARY) ||
  controls.filters.length > 1;

const getChartLabels = (data: TrendData[]) => {
  const chartLabelYears = new Set(
    data.map((trend) => moment(trend.group, ['WW-YYYY', 'MM-DD-YYYY']).year),
  );
  const addYearToChartLabels = chartLabelYears.size > 1;

  const chartLabels = data.map((trend) => {
    const parsedDate: Moment = moment(trend.group, ['WW-YYYY', 'MM-DD-YYYY']);
    return parsedDate.format(addYearToChartLabels ? 'MMM DD, YYYY' : 'MMM DD');
  });

  return chartLabels;
};

const getTrendLineLabels = (controls: Controls) => {
  const topicLabel = controls.topicLabel
    ? `Topic: "${controls.topicLabel}"`
    : '';
  const searchTextLabel = controls.searchText
    ? `Search: "${controls.searchText}"`
    : '';
  const filtersLabel = controls.filters.length
    ? `Filters: ${controls.filters
        .filter((filter) => filter !== HAS_SUMMARY)
        .map((filter) => `"${FILTER_LABEL_LOOKUP[filter].label}"`)
        .join(', ')}`
    : '';

  return [topicLabel, searchTextLabel, filtersLabel]
    .filter((label) => !!label)
    .join(', ');
};

const adjustTrendsDataForUTC = (
  data: TopicLabelTrendGroup[],
  numberOfDays: number,
): TrendData[] => {
  if (numberOfDays === 1 && data.length > 1) {
    return [
      {
        group: data[data.length - 1].group,
        total_conversations: data.reduce(
          (acc, group) => acc + group.total_conversations,
          0,
        ),
        total_conversations_for_topic_label: data.reduce(
          (acc, group) => acc + group.total_conversations_for_topic_label,
          0,
        ),
      },
    ];
  }
  return data;
};

const TrendChart = ({
  isTopicLabelTrendsLoading,
  controls,
  data,
  days,
}: Props) => {
  const borderColorExtraDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--border-color-extra-dim`);

  const borderColorDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--border-color-dim`);

  const textColorDim = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--text-color-dim`);

  const bodyTextTypeface = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--body-text-typeface`);

  const colorBackgroundTooltip = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-background-product-tooltip`);

  const colorTextTooltip = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-text-product-tooltip`);

  // Color getters for ChartJS
  const colorTopicPrimary = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-primary`);

  const colorTopicPrimaryHover = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-primary-hover`);

  const colorTopicSecondary = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-secondary`);

  const colorTopicSecondaryHover = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(`--color-topic-secondary-hover`);

  const getChartOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    height: 88,
    scales: {
      height: 99,
      xAxes: [
        {
          gridLines: {
            color: 'transparent',
            zeroLineColor: borderColorDim,
          },
          ticks: {
            beginAtZero: true,
            fontColor: textColorDim,
            fontFamily: bodyTextTypeface,
            fontSize: 12,
            padding: 0,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderSkipped: 'bottom',
            color: borderColorExtraDim,
            zeroLineColor: borderColorDim,
            offsetGridLines: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 50,
            fontColor: textColorDim,
            fontFamily: bodyTextTypeface,
            fontSize: 12,
            padding: 3,
          },
        },
      ],
    },
    legend: {
      display: false,
      labels: {
        defaultFontColor: 'red',
        defaultFontSize: 100,
      },
    },
    tooltips: {
      enabled: true,
      backgroundColor: colorBackgroundTooltip,
      titleFontFamily: bodyTextTypeface,
      titleFontSize: 14,
      titleFontStyle: 'bold',
      titleFontColor: colorTextTooltip,

      bodyFontFamily: bodyTextTypeface,
      bodyFontSize: 12,
      bodyFontStyle: 'normal',
      bodyFontColor: colorTextTooltip,
      borderColor: 'transparent',
      borderWidth: 0,
      xPadding: 12,
      yPadding: 6,
      cornerRadius: 6,
      displayColors: false,
    },
  });

  const trendLineLabels = getTrendLineLabels(controls);

  const datasets: Array<any> = [
    ...(shouldRenderLineChart(controls)
      ? [
          {
            label: trendLineLabels
              ? `Total conversations for (${trendLineLabels})`
              : 'Total converstions',
            data: data.map(
              (trend) => trend.total_conversations_for_topic_label,
            ),
            fill: false,
            type: 'line',

            // styles
            backgroundColor: colorTopicPrimary,
            borderColor: colorTopicPrimary,
            borderDash: [6, 4],
            borderDashOffset: 20,
            borderWidth: 1,
            hoverBackgroundColor: colorTopicPrimaryHover,
            hoverBorderColor: colorTopicPrimaryHover,
            lineTension: 0,
            pointBackgroundColor: colorTopicPrimary,
            pointHitRadius: 6,
          },
        ]
      : []),
    {
      label: 'Total Conversations',
      data: data.map((trend) => trend.total_conversations),
      fill: false,
      type: 'bar',
      barPercentage: 0.96,
      borderColor: 'transparent',
      borderWidth: 1,
      borderSkipped: 'bottom',

      // styles
      backgroundColor: shouldRenderLineChart(controls)
        ? colorTopicSecondary
        : colorTopicPrimary,
      hoverBackgroundColor: shouldRenderLineChart(controls)
        ? colorTopicSecondaryHover
        : colorTopicPrimaryHover,
      maxBarThickness: 30,
    },
  ];

  const trends = adjustTrendsDataForUTC(data, days);

  const chartData = {
    labels: getChartLabels(trends),
    datasets,
  };

  if (isTopicLabelTrendsLoading) {
    return <styles.SkeletonCard style={{ height: 360 }} />;
  }

  return (
    <styles.Chart>
      <Bar data={chartData} height={360} options={getChartOptions()} />
    </styles.Chart>
  );
};

export default TrendChart;
