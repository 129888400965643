import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { useUser } from '../controllers/contexts/user';

interface LogRocketPayload {
  [propName: string]: string | number | boolean;
}

export const useLogRocket = (
  initString: string,
  userId: string,
  payload: LogRocketPayload,
) => {
  useEffect(() => {
    // Return early if no user ID or if not in the production environment
    if (!userId || window.location.host !== 'app.postscript.io') return;

    LogRocket.init(initString);

    LogRocket.identify(userId, payload);
  }, [initString, userId, payload]);
};

export const useIdentifyLogRocketUser = (initString: string) => {
  const {
    user: { id: userId, username, shop_id: shopId, is_admin: isAdmin },
  } = useUser();

  useLogRocket(initString, userId.toString(), {
    name: username,
    jwt_shop_id: shopId,
    is_admin: isAdmin,
    user_id: userId,
  });
};
