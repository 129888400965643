import { nameofFactory } from 'components/flowBuilder/types/util';
import { HELP_ARTICLES } from 'constants/constants';
import { PopupPage, QuestionTypes } from '../types';

const nameofPage = nameofFactory<PopupPage>();

export const CUSTOM_SUCCESS_MESSAGE_VALIDATION_ERROR =
  '100 character limit exceeded';

export const POPUP_TYPES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
} as const;

export const V2_CONTENT_FIELDS = {
  preHeadline: {
    name: nameofPage('preHeadline'),
    label: 'Pre-headline (optional)',
  },
  headline: { name: nameofPage('headline'), label: 'Headline' },
  postHeadline: {
    name: nameofPage('postHeadline'),
    label: 'Post-headline (optional)',
  },
  submitButtonText: {
    name: nameofPage('submitButtonText'),
    label: 'Button text',
  },
  primarySubmitButtonText: {
    name: nameofPage('submitButtonText'),
    label: 'Primary button text',
  },
  secondarySubmitButtonText: {
    name: nameofPage('secondarySubmitButtonText'),
    label: 'Secondary button text',
  },
  closeButtonText: {
    name: nameofPage('closeButtonText'),
    label: 'Close button text',
  },
} as const;

export const QUESTION_TYPES = {
  EMAIL: 'EMAIL',
  MONTH_AND_DAY: 'MONTH_AND_DAY',
  PHONE: 'PHONE',
  RADIO: 'RADIO',
  TEXT: 'TEXT',
} as const;

export const OPT_IN_METHODS = {
  DOUBLE_OPT_IN: 'DOUBLE_OPT_IN',
  LINK_CLICK: 'LINK_CLICK',
  ONE_TIME_PASSCODE: 'ONE_TIME_PASSCODE',
  TWO_TOUCH: 'TWO_TOUCH',
} as const;

export const SUBSCRIBER_PROPERTY_TYPES = {
  TEXT: 'text',
  MONTH_AND_DAY: 'month_and_day',
  UNKNOWN: null,
} as const;

export const QUESTION_TYPE_LABELS: Record<QuestionTypes, string> = {
  [QUESTION_TYPES.EMAIL]: 'Email',
  [QUESTION_TYPES.MONTH_AND_DAY]: 'Month and day',
  [QUESTION_TYPES.PHONE]: 'Phone',
  [QUESTION_TYPES.RADIO]: 'Multiple choice',
  [QUESTION_TYPES.TEXT]: 'Text',
};

export const MAX_QUESTIONS = 2;

export const EDITOR_GRID_AREAS = {
  TABS: 'TABS',
  FORM: 'FORM',
  PREVIEW: 'PREVIEW',
};

export const EMAIL_ATTRIBUTE_KEY = 'email';

export const PHONE_ATTRIBUTE_KEY = 'phone';

export const CSS_HELP_ITEMS = [
  {
    text: 'Popup CSS Customization Glossary',
    type: undefined,
    url: HELP_ARTICLES.popupCssCustomizationGlossary,
  },
  {
    text: 'Customize Your Desktop Popup with CSS',
    type: POPUP_TYPES.DESKTOP,
    url: HELP_ARTICLES.customizeYourDesktopPopupWithCss,
  },
  {
    text: 'Desktop Popup Template Gallery',
    type: POPUP_TYPES.DESKTOP,
    url: HELP_ARTICLES.desktopPopupCssTemplateGallery,
  },
  {
    text: 'Customize Your Mobile Popup with CSS',
    type: POPUP_TYPES.MOBILE,
    url: HELP_ARTICLES.customizeYourMobilePopupWithCss,
  },
  {
    text: 'Mobile Popup Template Gallery',
    type: POPUP_TYPES.MOBILE,
    url: HELP_ARTICLES.mobilePopupTemplateGallery,
  },
] as const;

/**
* This is the minimum height for a v2 mobile popup to not scroll at creation
  across Win/Mac and three major browsers with the following characteristics:
- v2 style default
- full style
- all default field text values
- two line primary button
- max-height logo
- powered by postscript
- one v2 phone question
*/
export const EDITOR_PREVIEW_HEIGHT = 704;

export const EDITOR_PREVIEW_MOBILE_WIDTH = 375;

export const TRIGGER_TYPES = {
  DELAY: 'Delay',
  EXIT: 'Exit',
  CUSTOM: 'Custom',
} as const;
