import PageHeader from 'components/layout/PageHeader';
import { MultiViewDebugWrapper } from '../../components/MultiViewDebugWrapper';
import ConversationIntelligence from '../ConversationIntelligence';
import * as styles from '../styles';

export const SmsSalesConversationIntelligenceView = (): JSX.Element => {
  return (
    <MultiViewDebugWrapper>
      <PageHeader
        pageTitle={
          <>
            Conversation Intelligence
            <styles.BetaBadge size="small" variant="success">
              Beta
            </styles.BetaBadge>
          </>
        }
        sticky={false}
      />
      <ConversationIntelligence />
    </MultiViewDebugWrapper>
  );
};
