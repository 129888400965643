import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Controls } from '../components/ConversationIntelligenceControls';

const useSyncHistoryWithControls = (controls: Controls) => {
  const history = useHistory();

  useEffect(() => {
    const updateHistoryFromControls = () => {
      const newQueryParams = new URLSearchParams();
      if (controls.shopIds.length > 0) {
        newQueryParams.set('shop_ids', controls.shopIds.join(','));
      }
      if (controls.agentIds.length > 0) {
        newQueryParams.set('agent_ids', controls.agentIds.join(','));
      }
      if (controls.filters.length > 0) {
        newQueryParams.set('filters', controls.filters.join(','));
      }
      if (controls.searchText) {
        newQueryParams.set('search_text', controls.searchText);
      }
      if (controls.searchDirection) {
        newQueryParams.set('search_direction', controls.searchDirection);
      }
      if (controls.topicLabel) {
        newQueryParams.set('topic_label', controls.topicLabel);
      }
      if (controls.tag) {
        newQueryParams.set('tag', controls.tag);
      }
      if (controls.sortBy) {
        newQueryParams.set('sort_by', controls.sortBy);
      }
      if (controls.dateRangeStart) {
        newQueryParams.set('date_range_start', controls.dateRangeStart);
      }
      if (controls.dateRangeEnd) {
        newQueryParams.set('date_range_end', controls.dateRangeEnd);
      }
      if (controls.page) {
        newQueryParams.set('page', controls.page.toString());
      }
      history.push(`?${newQueryParams.toString()}`);
    };

    updateHistoryFromControls();
  }, [controls, history]);
};

export default useSyncHistoryWithControls;
