import { DataCard, Icon, IconSet } from '@postscript/components';
import { VIEW_OPTIONS } from '../../constants';
import { useView } from '../../hooks/useView';
import * as styles from '../styles';
import { ConversationIntelligenceMetricsData } from '../types';
import { formatNumberWithCommas } from '../utils/phone';

interface Props {
  metrics: ConversationIntelligenceMetricsData;
  onDownload: () => void;
  dateLabel: string;
  isLoading: boolean;
}

export const ConversationIntelligenceMetrics = ({
  metrics,
  onDownload,
  isLoading,
  dateLabel,
}: Props): JSX.Element => {
  const { view } = useView();

  const totalConversationsLabel =
    metrics.matching_conversations < metrics.total_conversations
      ? `${Math.round(
          metrics.total_conversations
            ? (metrics.matching_conversations / metrics.total_conversations) *
                100
            : 0,
        ).toFixed(2)}% of ${formatNumberWithCommas(
          metrics.total_conversations.toString(),
        )}`
      : undefined;

  const uniqueSubscribersLabel =
    metrics.matching_subscribers === metrics.total_subscribers
      ? ''
      : `${Math.round(
          (100 * metrics.matching_subscribers) / metrics.total_subscribers,
        ).toFixed(2)}% of ${formatNumberWithCommas(
          metrics.total_subscribers.toString(),
        )} ${dateLabel}`;

  if (isLoading) {
    return (
      <>
        <styles.SkeletonCard />
        <styles.SkeletonCard />
      </>
    );
  }

  return (
    <>
      <DataCard
        accordion={false}
        metric={formatNumberWithCommas(
          metrics.matching_conversations.toString(),
        )}
        title="Total Conversations"
        className={view === VIEW_OPTIONS.AI_DEBUGGER ? 'tall' : ''}
        visual={
          view === VIEW_OPTIONS.AI_DEBUGGER ? (
            <styles.JsonButton
              className={
                metrics.matching_conversations < metrics.total_conversations
                  ? 'drop'
                  : ''
              }
              onClick={() => onDownload()}
              size="small"
              variant="secondary"
              monochrome
            >
              Download JSON
            </styles.JsonButton>
          ) : (
            <Icon component={IconSet.Message} size={30} />
          )
        }
        periodLabel={totalConversationsLabel}
      />
      <DataCard
        accordion={false}
        metric={formatNumberWithCommas(metrics.matching_subscribers.toString())}
        title="Unique Subscribers"
        visual={<Icon component={IconSet.Users} size={30} />}
        periodLabel={uniqueSubscribersLabel}
      />
    </>
  );
};
