import {
  Badge,
  Button,
  EmptyState,
  Heading,
  IconSet,
  toast,
} from '@postscript/components';
import MessageThread from 'components/sales/components/MessageThread';
import { useEffect, useRef, useState } from 'react';
import { ConversationWithInsights } from '../../hooks/useConversationSummaries';
import useSubscriber from '../../hooks/useSubscriber';
import { ShopData } from '../../types';
import { FOCUSABLE_ELEMENTS } from '../constants';
import * as styles from '../styles';
import { getBadgeStyles, getIsMultiShop } from '../utils';
import { formatPhoneNumber } from '../utils/phone';
import LogLine from './LogLine';

const getIdentifier = (conversation: ConversationWithInsights) => {
  const formattedName =
    conversation.subscriber?.first_name && conversation.subscriber?.last_name
      ? `${
          conversation.subscriber.first_name
        } ${conversation.subscriber.last_name[0].toUpperCase()}`
      : '';

  const formattedPhoneNumber =
    conversation.subscriber?.phone_number &&
    formatPhoneNumber(conversation.subscriber?.phone_number);

  const identifier =
    (formattedName || formattedPhoneNumber) &&
    `${formattedName || ''}${formattedName && formattedPhoneNumber && ' · '}${
      formattedPhoneNumber || ''
    }`;

  return identifier;
};

const copyConversationLinkToClipboard = (selectedCardId: string) => {
  const url = `${
    window.location.origin + window.location.pathname
  }?search_text=${selectedCardId}`;
  navigator.clipboard.writeText(url);
  toast.success('Copied.');
};

const copyConversationToClipboard = (
  conversation: ConversationWithInsights,
) => {
  const text = conversation.messages
    .map((message, index, messages) => {
      const previousDirection =
        index > 0 ? messages[index - 1].direction : null;
      const messageDirection =
        message.direction === 'INBOUND' ? 'INBOUND' : 'OUTBOUND';

      const extraNewline = previousDirection !== messageDirection ? '\n' : '';

      return `${extraNewline}${messageDirection}: ${message.body}`;
    })
    .join('\n');
  navigator.clipboard.writeText(text);
  toast.success('Copied.');
};

const setFocus = (
  modalRef: React.RefObject<HTMLDivElement>,
  firstElementRef?: React.RefObject<HTMLElement>,
) => {
  if (firstElementRef?.current) {
    firstElementRef.current.focus();
    return;
  }
  if (modalRef.current) {
    const firstFocusableElement = modalRef.current.querySelector(
      FOCUSABLE_ELEMENTS,
    ) as HTMLElement;
    firstFocusableElement?.focus();
  }
};

const trapFocus = (
  event: KeyboardEvent,
  modalRef: React.RefObject<HTMLDivElement>,
) => {
  if (!modalRef.current || event.key !== 'Tab') return;

  const focusable = modalRef.current.querySelectorAll(FOCUSABLE_ELEMENTS);
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];

  if (event.shiftKey && document.activeElement === firstFocusable) {
    event.preventDefault();
    (lastFocusable as HTMLElement).focus();
  }

  if (!event.shiftKey && document.activeElement === lastFocusable) {
    event.preventDefault();
    (firstFocusable as HTMLElement).focus();
  }
};

const returnFocusToActivator = (activator: HTMLElement) => {
  if (typeof activator?.focus === 'function') {
    activator.focus();
  }
};

interface Props {
  subscriberId?: number;
  conversation: ConversationWithInsights;
  selectedCardId: string | null;
  shopData: ShopData | undefined;
  clearConversation: () => void;
  chatDrawerRef: React.RefObject<HTMLDivElement>;
}

export const ConverstionDrawer = ({
  subscriberId,
  conversation,
  selectedCardId,
  shopData,
  chatDrawerRef,
  clearConversation,
}: Props): JSX.Element | null => {
  const { data: subscriber } = useSubscriber(subscriberId);
  const [drawerActivator, setDrawerActivator] = useState<Element | null>(null);
  const messageThreadRef = useRef<HTMLUListElement | null>(null);

  const isMultiShop = getIsMultiShop();

  const identifier = getIdentifier(conversation);

  /* auto scroll to bottom of message thread */
  useEffect(() => {
    if (messageThreadRef.current) {
      const targetMessage =
        messageThreadRef.current.querySelector('li:last-of-type');

      if (targetMessage) {
        targetMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [subscriber]);

  useEffect(() => {
    const eventHandlers = (event: KeyboardEvent) => {
      trapFocus(event, chatDrawerRef);
      if (event.key === 'Escape') {
        clearConversation();
      }
    };

    if (!selectedCardId) {
      returnFocusToActivator(drawerActivator as HTMLElement);
    } else {
      setDrawerActivator(document.activeElement);
      setFocus(chatDrawerRef);
      window.addEventListener('keydown', eventHandlers);
    }

    return () => {
      window.removeEventListener('keydown', eventHandlers);
    };
  }, [selectedCardId]);

  if (!subscriberId || !selectedCardId) {
    return null;
  }

  return (
    <>
      <styles.ChatCard>
        <styles.ChatHeader>
          {subscriber && (
            <styles.ChatSummary>
              {isMultiShop && (
                <Badge style={getBadgeStyles(shopData)}>
                  {conversation.shop?.name || 'Unknown Shop'}
                </Badge>
              )}
              <styles.SummaryHeader>
                {identifier && <Heading size="x-small">{identifier}</Heading>}
                <LogLine conversation={conversation} />
              </styles.SummaryHeader>
            </styles.ChatSummary>
          )}
          <Button
            icon={IconSet.Close}
            monochrome
            size="large"
            variant="text"
            onClick={clearConversation}
            style={{ marginLeft: 'auto' }}
          />
        </styles.ChatHeader>
        {subscriber ? (
          <>
            <styles.BorderedChatSubContainer>
              <MessageThread
                subscriber={subscriber}
                messageThreadRef={messageThreadRef}
              />
            </styles.BorderedChatSubContainer>

            <styles.ChatActions>
              <Button
                onClick={() => copyConversationLinkToClipboard(selectedCardId)}
                icon={IconSet.Link}
                size="small"
                variant="secondary"
              >
                Copy Link
              </Button>
              <Button
                onClick={() => copyConversationToClipboard(conversation)}
                icon={IconSet.Text}
                size="small"
                variant="secondary"
              >
                Copy Conversation
              </Button>
            </styles.ChatActions>
          </>
        ) : (
          <styles.ChatEmpty>
            <EmptyState size="medium" description="Loading conversation..." />
          </styles.ChatEmpty>
        )}
      </styles.ChatCard>
      <styles.ChatBackdrop onClick={clearConversation} />
    </>
  );
};

export default ConverstionDrawer;
