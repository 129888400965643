// eslint-disable-next-line import/no-extraneous-dependencies
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';
import { api } from 'controllers/network/apiClient';
import { useIdentifyLogRocketUser } from 'hooks/useLogRocket';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const allowedOrigins = [
  'https://omni.postscript.io',
  'https://postscript.embed-omniapp.co',
];

function getSignedURL(id: string) {
  return api.get(`/v2/analytics/omni/signed_url?dashboard_id=${id}`);
}

function setFrameHeight(iframe: HTMLIFrameElement, height: number) {
  // eslint-disable-next-line no-param-reassign
  iframe.style.height = `${height + 48}px`;
}

const StyledFrame = styled.iframe`
  width: 100%;
`;

interface Props {
  id: string;
}

export default function OmniDashboard({ id }: Props): JSX.Element {
  const ref = useRef<HTMLIFrameElement>(null);
  const [url, setURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [id]);

  useIdentifyLogRocketUser('ssovkv/omni-dashboard');

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      // Only accept size event messages from the Omni instance
      if (
        !allowedOrigins.includes(event.origin) ||
        event.data.name !== 'size' ||
        !ref.current
      ) {
        return;
      }

      const { height } = event.data.payload;
      setFrameHeight(ref.current, height);
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [ref]);

  useQuery(['omniSignedURL', id], () => getSignedURL(id), {
    select: ({ url }) => url,
    onSuccess: setURL,
    staleTime: 0,
  });

  return (
    <>
      {isLoading && <LoadingSpinner>Loading dashboard...</LoadingSpinner>}
      <StyledFrame
        ref={ref}
        title="Omni Dashboard"
        src={url}
        referrerPolicy="origin"
        onLoad={() => setIsLoading(false)}
        hidden={isLoading}
      />
    </>
  );
}
