import { createContext, useContext } from 'react';
import { ShopOption, User } from 'types';

export interface UserContextType {
  user: User;
  getUserInfo: () => void;
  updateUserInfo: (user: Partial<User>) => void;
  shops: ShopOption[];
  updateShops: () => void;
  isFetchingShops: boolean;
}

const UserContext = createContext<UserContextType>({
  user: {} as User, // There will always be a User since these default values are overriden when rendering the Provider in GlobalAuthenticatedContexts
  getUserInfo: () => undefined,
  updateUserInfo: () => undefined,
  shops: [],
  updateShops: () => undefined,
  isFetchingShops: false,
});

export const useUser = () => useContext(UserContext);

export default UserContext;
