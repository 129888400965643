import { IconSet } from '@postscript/components';
import { PERMISSIONS } from 'components/account/AccountView/users/constants';
import {
  BCC,
  DATA_LABS,
  PS_LABS,
  PS_PAY,
  SHOPPER_CIUI,
  SMS_SALES_CONVERSATIONS,
  SMS_SALES_NAV,
  SSAI,
  SSAI_NAV,
  USAGE_BILLING_ENABLED,
} from 'components/admin/utils/feature-flags';
import { FEATURE_KEYS } from 'components/billing/common/constants';
import { NavMenuItem } from 'components/navigation/NavMenu';
import { ROUTE_POPUPS_BLOCK, SUBROUTE_LIST } from 'components/popups/constants';
import UnresolvedNotificationsBadge from 'components/responses/UnresolvedNotificationsBadge';

const getAnalyticsLink = (url: string) =>
  window.location.pathname.includes('/analytics')
    ? `${url}${window.location.search}`
    : url;

export const defaultMenuConfig: NavMenuItem[] = [
  {
    ariaLabelledBy: 'nav-dashboard-title',
    icon: IconSet.Dashboard,
    label: 'Dashboard',
    id: 'dashboard',
    link: '/',
  },
  {
    ariaLabelledBy: 'nav-sales-title',
    icon: IconSet.Dollar,
    label: 'SMS Sales',
    id: 'sms-sales',
    labsFeatureFlag: SMS_SALES_NAV,
    subMenu: [
      {
        link: '/sms-sales/performance',
        label: 'Performance',
        id: 'sms-sales-perforance',
      },
      {
        labsFeatureFlag: SMS_SALES_CONVERSATIONS,
        link: '/sms-sales/conversations',
        label: 'Conversations',
        id: 'sms-sales-conversations',
      },
      {
        link: '/sms-sales/conversation-intelligence?filters=HAS_SUMMARY',
        label: 'Conversation Intelligence',
        id: 'sms-sales-conversation-intelligence',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-messaging-title',
    icon: IconSet.Message,
    label: 'Messaging',
    id: 'messaging',
    notification: UnresolvedNotificationsBadge(true),
    subMenu: [
      { link: '/campaigns', label: 'Campaigns', id: 'messaging-campaigns' },
      {
        link: '/automations',
        label: 'Automations',
        id: 'messaging-automations',
      },
      { link: '/segments', label: 'Segments', id: 'messaging-segments' },
      {
        link: '/responses',
        label: 'Responses',
        id: 'messaging-responses',
        notification: UnresolvedNotificationsBadge(false),
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-list-growth-title',
    icon: IconSet.UsersPlus,
    label: 'Acquisition',
    id: 'acquisition',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      {
        id: 'acquisition-popups',
        label: 'Legacy Popups',
        link: '/popups',
      },
      {
        id: 'acquisition-popups-block-popups',
        label: 'Popups',
        link: `${ROUTE_POPUPS_BLOCK}${SUBROUTE_LIST}`,
        tag: 'Beta',
      },
      {
        id: 'acquisition-keywords',
        label: 'Keywords',
        link: '/keywords',
      },
      {
        id: 'acquisition-list-growth',
        label: 'List Growth Tools',
        link: '/grow',
      },
      {
        id: 'acquisition-checkout',
        label: 'Checkout Collection',
        link: '/checkout',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-analytics-title',
    icon: IconSet.Leaderboard,
    label: 'Analytics',
    id: 'analytics',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      {
        link: (): string => getAnalyticsLink(`/analytics/messages`),
        label: 'Messages',
        id: 'analytics-messages',
      },
      {
        link: (): string => getAnalyticsLink(`/analytics/subscribers`),
        label: 'Subscribers',
        id: 'analytics-subscribers',
      },
      {
        link: '/analytics/ai',
        label: 'AI',
        id: 'analytics-ai',
        labsFeatureFlag: BCC,
      },
      {
        link: (): string => `/analytics/shopper-trends`,
        label: 'Shopper Trends',
        id: 'shopper-trends',
        labsFeatureFlag: SHOPPER_CIUI,
      },
      {
        link: '/trends',
        label: 'Trends',
        id: 'trends',
        gatedFeatureKey: FEATURE_KEYS.omni_trends,
      },
      { link: '/account/reports', label: 'Reports', id: 'analytics-reports' },
    ],
  },
  {
    ariaLabelledBy: 'nav-brand-center-title',
    icon: IconSet.Sparkles,
    label: 'Brand Center',
    id: 'brand-center',
    labsFeatureFlag: BCC,
    subMenu: [
      {
        link: '/brand/voice',
        label: 'Voice and Tone',
        id: 'voice-and-tone',
      },
      {
        link: '/brand/sales-ai',
        label: 'Shopper',
        id: 'sales-ai',
        labsFeatureFlag: SSAI_NAV,
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-pspay-title',
    icon: IconSet.PSTextToBuy,
    label: 'Text-to-Buy',
    id: 'pspay',
    labsFeatureFlag: PS_PAY,
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      {
        link: '/pspay/manage',
        label: 'Branding',
        id: 'pspay-manage',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-apps-title',
    icon: IconSet.Apps,
    label: 'Apps',
    id: 'apps',
    restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
    subMenu: [
      { link: '/integrations/all', label: 'All', id: 'apps-all' },
      {
        link: '/integrations/affiliates',
        label: 'Affiliate',
        id: 'apps-affiliates',
      },
      {
        link: '/integrations/automations',
        label: 'Automation',
        id: 'apps-automations',
      },
      {
        link: '/integrations/conversion',
        label: 'Conversion',
        id: 'apps-conversion',
      },
      {
        link: '/integrations/customer-support',
        label: 'Customer Support',
        id: 'apps-customer-support',
      },
      {
        link: '/integrations/data',
        label: 'Data',
        id: 'apps-data',
      },
      {
        link: '/integrations/email',
        label: 'Email',
        id: 'apps-email',
      },
      {
        link: '/integrations/list-growth',
        label: 'List Growth',
        id: 'apps-list-growth',
      },
      {
        link: '/integrations/reviews-loyalty',
        label: 'Reviews & Loyalty',
        id: 'apps-reviews-loyalty',
      },
      {
        link: '/integrations/shipping',
        label: 'Shipping',
        id: 'apps-shipping',
      },
      {
        link: '/integrations/subscriptions',
        label: 'Subscription',
        id: 'apps-subscriptions',
      },
    ],
  },
  {
    ariaLabelledBy: 'nav-account-title',
    icon: IconSet.Settings2,
    label: 'Account',
    id: 'account',
    subMenu: [
      { link: '$selectShop', label: 'Select Shop', id: 'account-select-shop' },
      {
        link: '/account/settings',
        label: 'Shop Settings',
        id: 'account-settings',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/profile',
        label: 'Profile',
        id: 'profile',
      },
      {
        link: '/account/users',
        label: 'Users',
        id: 'account-users',
        permission: PERMISSIONS.user_read,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/tracking',
        label: 'Tracking',
        id: 'account-tracking',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/plan',
        label: 'Plan',
        id: 'account-plan',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/billing',
        label: 'Billing',
        id: 'account-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/billing',
        label: 'Billing',
        id: 'account-usage-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        permission: PERMISSIONS.billing_read,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/api',
        label: 'API',
        id: 'account-api',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/ps-labs',
        label: 'PS Labs',
        id: 'account-ps-labs',
        featureFlag: PS_LABS,
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/account/contact-card',
        label: 'Shop Contact Card',
        id: 'account-contact-card',
        restrictions: [PERMISSIONS.limit_sms_sales_agent_access],
      },
      {
        link: '/subscriber-list-uploads',
        label: 'Subscriber List Uploads',
        id: 'subscriber-list-uploads',
      },
      { link: '$logOut', label: 'Logout', id: 'account-logout' },
    ],
  },
  {
    ariaLabelledBy: 'nav-admin-title',
    icon: IconSet.Key,
    label: 'Postscript Admin',
    id: 'admin',
    subMenu: [
      {
        link: '/account-overview',
        label: 'Account Overview',
        id: 'admin-account-overview',
      },
      {
        link: '/ssai-config',
        label: 'SSAI Config',
        id: 'admin-ssai-config',
        labsFeatureFlag: SSAI,
      },
      {
        link: '/admin/templates',
        label: 'Templates',
        id: 'admin-templates',
      },
      {
        link: '/admin/billing',
        label: 'Billing',
        id: 'admin-billing',
        featureFlag: USAGE_BILLING_ENABLED,
        featureFlagOptOut: true,
      },
      {
        link: '/admin/messaging-status',
        label: 'Messaging',
        id: 'admin-messaging',
      },
      {
        link: '/admin/announcements',
        label: 'Service Announcements',
        id: 'admin-announcements',
      },
      {
        link: '/admin/ps-labs',
        label: 'PS Labs',
        id: 'admin-ps-labs',
      },
      { link: '/admin/partners', label: 'Partners', id: 'admin-partners' },
      {
        link: '/admin/data-labs',
        label: 'Data Labs',
        id: 'data-labs',
        labsFeatureFlag: DATA_LABS,
      },
      {
        link: '/omni',
        label: 'Omni Sandbox',
        id: 'omni',
      },
      {
        link: '/admin/list-management',
        label: 'List Management',
        id: 'admin-list-management',
      },
    ],
  },
];

export const salesMenuConfig: NavMenuItem[] = [
  {
    ariaLabelledBy: 'nav-sales-conversations-title',
    icon: IconSet.Message,
    label: 'My Prospects',
    id: 'inbox',
    link: '/workspace/my-prospects',
  },
  {
    ariaLabelledBy: 'nav-sales-conversation-intelligence',
    icon: IconSet.TwoWayConvo,
    label: 'Conversation Intelligence',
    id: 'conversation-intelligence',
    link: '/workspace/conversation-intelligence',
  },
  {
    ariaLabelledBy: 'nav-sales-marketing-title',
    icon: IconSet.DoorExit,
    label: 'Marketing',
    id: 'marketing',
    link: '/',
  },
];
