import PageHeader from 'components/layout/PageHeader';
import ConversationIntelligence from '../ConversationIntelligence';
import * as styles from '../styles';

export const SalesWorkspaceConversationIntelligenceView = (): JSX.Element => {
  return (
    <>
      <PageHeader
        pageTitle={
          <>
            Conversation Intelligence
            <styles.BetaBadge size="small" variant="success">
              Beta
            </styles.BetaBadge>
          </>
        }
        sticky={false}
      />
      <ConversationIntelligence />
    </>
  );
};
