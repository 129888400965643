import {
  Badge,
  Button,
  Card,
  EmptyState,
  Heading,
  Icon,
  Layout,
  TabGroup as TabGroupComponent,
  TabPanel,
} from '@postscript/components';
import styled, { css } from 'styled-components';
import MediaQueries from 'utils/mediaQueries';
import { isBubAI } from './utils';

const getBreakpoint = () => {
  return isBubAI() ? '1280px' : '767px';
};

export const BetaBadge = styled(Badge)`
  vertical-align: super;
  margin-left: var(--spacing-1);
  letter-spacing: normal;
`;

export const CIColumns = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  position: relative;
  max-width: 100%;
`;

export const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-1);

  .view-conversation-button {
    min-width: 148px;
  }
  .subscriber-details-button {
    min-width: 138px;
  }
`;

export const CardEmptyState = styled(EmptyState)`
  padding: var(--spacing-4) 0;

  .no-insights & {
    flex: 1 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-bottom: var(--spacing-2);
`;

interface HighlightProps {
  highlighted?: boolean;
}

export const HashtagLineItem = styled.div<HighlightProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3px 6px 3px 3px;
  border-radius: var(--border-radius-x-small);
  gap: var(--spacing-2);
  width: 100%;
  cursor: default;
  transition: background var(--hover-transition);

  ${({ highlighted }) =>
    highlighted
      ? css`
          background: var(--color-topic-primary);
        `
      : css`
          &:hover {
            background: var(--border-color-extra-dim);
          }
        `}
`;

export const Chart = styled.div`
  .single-col & {
    max-width: 1236px;
  }
`;

export const HashtagCard = styled(Card)`
  border-radius: var(--border-radius-medium);
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  padding: var(--spacing-3);
  margin-bottom: var(--spacing-2);
  width: 100%;
  position: sticky;
  top: var(--spacing-2);
`;

export const HashtagHeader = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: var(--spacing-1);
`;

export const HashtagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  align-self: stretch;
`;

export const MainColumn = styled(Layout)`
  flex: 1 0;
  gap: var(--spacing-6);

  > * {
    width: 100%;
  }

  .single-col & {
    max-width: 100%;
  }

  .multi-col & {
    max-width: calc(100% - 360px);

    @media only screen and (max-width: ${getBreakpoint()}) {
      max-width: calc(100% - 288px);
    }
  }
`;

export const TitleAndChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
`;

export const MetricsContainer = styled.div`
  display: flex;
  gap: var(--spacing-2);

  > * {
    flex: 1 0;
    max-width: 288px !important;
    position: relative;

    &.tall {
      padding-bottom: var(--spacing-10);
    }
  }

  .multi-col & {
    @media only screen and (max-width: 876px) {
      flex-direction: column;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1164px) {
      flex-direction: column;
    }
  }

  .single-col & {
    max-width: 1236px;
  }
`;

export const SummaryCardsAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

export const PaginationWrapper = styled.div`
  padding-top: var(--spacing-4);

  .single-col & {
    max-width: 1236px;
  }
`;

export const JsonButton = styled(Button)`
  position: absolute;
  left: var(--spacing-4);
  top: 84px;

  &.drop {
    top: 108px;
  }
`;

export const SkeletonCard = styled.div<{ height?: number }>`
  flex: 1 0 auto;
  border-radius: var(--border-radius-medium);
  height: ${(props) => props.height || 120}px;
  padding: var(--spacing-4) var(--spacing-5);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;

  .single-col & {
    max-width: 1236px;
  }
`;

export const SkeletonCardTall = styled(SkeletonCard)`
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;

  .single-col & {
    max-width: 1236px;
  }
`;

export const TabGroup = styled(TabGroupComponent)`
  display: flex;
  padding-bottom: var(--spacing-2);
  padding-left: var(--spacing-1);
`;

export const ViewMoreTagsWrapper = styled.div`
  margin-top: var(--spacing-2);
  position: relative;
  text-align: center;
  width: 100%;
`;

export const ViewMoreHr = styled.div`
  background: var(--border-color-dim);
  display: block;
  height: 1px;
  left: 0;
  margin: unset;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
`;

export const ViewMoreButtonWrapper = styled.span`
  position: relative;
  display: inline-block;
  background-color: var(--white);
  padding: var(--spacing-1);
  z-index: 1;
`;

export const TopicCategoryGroup = styled.div`
  align-self: stretch;

  &:not(:last-child) {
    padding-bottom: var(--spacing-2);
  }
`;

export const TopicCategoryTitle = styled.div`
  display: flex;
  gap: var(--spacing-1);
  padding: 0px var(--spacing-1) var(--spacing-1);
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const SkeletonHashtagCard = styled(SkeletonCard)`
  flex: 1 0 auto;
  height: 312px;
  border-radius: var(--border-radius-medium);
  width: 100%;
  padding: var(--spacing-2);
  margin-bottom: var(--spacing-2);
  transition: background 250ms;

  background: var(--color-skeleton-loader);
  animation: skeletonLoaderAnimation 1000ms infinite;
`;

export const VoidCard = styled.div`
  background: var(--surface-bkg-color);
  height: 280px;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-small);
  display: flex;
  align-items: center;
  justify-content: center;

  .single-col & {
    max-width: 1236px;
  }
`;

export const RightColumn = styled.div`
  max-width: 420px;
  min-width: 360px;
  flex: 1 1 420px;
  border-radius: var(--border-radius-medium);
  align-self: stretch;

  @media only screen and (max-width: ${getBreakpoint()}) {
    max-width: 288px;
    min-width: 288px;
    flex: 1 1 288px;
  }
`;

export const ChatSubContainer = styled.div`
  position: relative;
  overflow: auto;
  padding: 0 var(--spacing-4);

  ul {
    li:last-child {
      padding-bottom: var(--spacing-4);
    }
  }
`;

export const BorderedChatSubContainer = styled(ChatSubContainer)`
  padding: 0;
  border-top: 1px solid var(--border-color-dim);
  border-bottom: 1px solid var(--border-color-dim);
`;

export const SummaryCard = styled.article`
  border: 1px solid transparent;
  position: relative;
  border-radius: var(--border-radius-medium);
  border: 1px solid transparent;
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  transition: border-color var(--hover-transition);
  padding: var(--spacing-4) var(--spacing-5);

  > div {
    display: flex;
    gap: var(--spacing-4);
    width: 100%;
  }

  &:not(.no-insights) > div {
    align-items: flex-start;
  }

  &.active {
    border: 1px solid var(--color-topic-primary);
    box-shadow: 0px 0px 5px 0px var(--color-topic-primary);
  }

  @media only screen and (max-width: ${getBreakpoint()}) {
    > div {
      flex-direction: column;
      gap: var(--spacing-3);
    }
  }

  .single-col & {
    max-width: 1236px;
  }
`;

export const TitleContainer = styled.div`
  background: var(--main-bkg-color);
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--text-color-dim);
  position: relative;

  .single-col & {
    max-width: 1236px;
  }
`;

export const SearchHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  align-self: stretch;
`;

export const SearchHeaderLogline = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  padding: 0 96px 0 var(--spacing-5);
  transition: opacity var(--hover-transition);
`;

export const SearchText = styled(Heading)`
  flex: 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & mark {
    color: var(--color-topic-primary);
  }
`;

export const SearchButtons = styled.div`
  display: flex;
  gap: var(--spacing-1);
  pointer-events: none;
`;

export const SummaryHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  width: 100%;
`;

export const SearchHeader = styled.div<{ filtersSelected: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: var(--spacing-2);
  min-height: 69px;
  cursor: pointer;

  background: transparent;
  border-radius: var(--border-radius-medium);
  transition: background var(--hover-transition),
    box-shadow var(--hover-transition);

  ${({ filtersSelected }) =>
    filtersSelected
      ? css`
          & ${SearchText} {
            color: var(--text-color);
          }
        `
      : css`
          &:not(:hover) ${SearchHeaderLogline} {
            opacity: 0;
          }
        `};

  &:hover {
    background: var(--surface-bkg-color);
    box-shadow: 0 0 0 1px var(--border-color-extra-dim) inset,
      var(--box-shadow-small);
  }
`;

export const LogLine = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
`;

export const LogLineItem = styled.div`
  display: inline-flex;
  gap: 3px;
`;

export const ChatCard = styled.article`
  background: var(--modal-bkg-color);
  box-shadow: var(--box-shadow-large);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: var(--spacing-4) var(--spacing-4) 72px var(--spacing-6); // 72 to account for chat button
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  max-width: 480px;

  transform: translateX(480px);
  transition: transform 500ms;
  will-change: transform;
`;

export const ChatBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 250ms;
  will-change: opacity;
`;

export const ChatContainer = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transition: z-index 1ms;

  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
          pointer-events: all;
          height: 100vh;
          width: 100%;
          z-index: 1000;

          ${ChatCard} {
            transform: translateX(0);
          }
          ${ChatBackdrop} {
            opacity: 1;
          }
        `
      : css`
          transition-delay: 500ms;
          pointer-events: none;
        `};
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-3);
`;

export const ChatSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2);
`;

export const ChatActions = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-1);
  padding: var(--spacing-2) 0 0;
`;

export const ChatEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--spacing-4);
  align-items: center;
  justify-content: center;
`;

export const SumaryCardLeftCol = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex: 1 1 auto;
  gap: var(--spacing-3);
  max-width: 720px;
`;

export const SumaryCardRightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);

  flex: 1 1;
  min-width: 204px;
  padding-left: var(--spacing-2);
  border-left: 1px solid var(--border-color-dim);

  @media only screen and (max-width: ${getBreakpoint()}) {
    flex-direction: column;
    flex: 1 0 100%;
    width: 100%;
    padding-left: 0;
    padding-top: var(--spacing-3);
    border-top: 1px solid var(--border-color-dim);
    border-left: none;
  }
`;

export const CardTabGroup = styled(TabGroup)`
  margin-top: -12px;
`;

export const CardTabPanel = styled(TabPanel)`
  margin-top: -6px;
`;

export const DebugContents = styled.div`
  padding: var(--spacing-1) var(--spacing-2);
  border: 1px solid var(--border-color-extra-dim);
  border-radius: var(--border-radius-small);
`;

export const Hashtags = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);

  .no-insights & {
    flex: 1 0;
  }
`;

export const CardSidebarItem = styled.div`
  display: flex;
  gap: var(--spacing-1);
  color: var(--text-color-dim);
  width: 100%;
  font: var(--body-text-x-small);
`;

export const Topic = styled.a<HighlightProps>`
  display: flex;
  gap: var(--spacing-1);
  color: var(--text-color-dim);
  width: 100%;
  font: var(--body-text-x-small);
  cursor: pointer;

  ${({ highlighted = false }) => {
    if (highlighted) {
      return css`
        color: var(--white);

        &:hover {
          color: var(--white);
          text-decoration: underline;
        }

        &:active {
          color: var(--gray-3);
          text-decoration: underline;
        }
      `;
    }
    return css`
      color: var(--text-color-dim);

      &:hover {
        color: var(--link-color);
        text-decoration: underline;
      }

      &:active {
        color: var(--link-color-hover);
        text-decoration: underline;
      }
    `;
  }}

  &.bold {
    font-weight: 650;
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 0;
    text-decoration: underline;
  }
`;

export const CardHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-4);
  padding: 0 var(--spacing-2) var(--spacing-2);

  .single-col & {
    max-width: 1236px;
  }
`;

export const CardHeading = styled(Heading).attrs({
  size: 'x-small',
})`
  color: var(--text-color-dim);
`;

export const FreqSent = styled.div`
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
`;

export const SentimentList = styled.ul`
  list-style-type: none;
  margin-top: var(--spacing-1);
`;

export const SentimentItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-1);
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-3);
  height: var(--spacing-3);
  margin-right: 9px;
`;

export const TopicPercentage = styled.div<HighlightProps>`
  font: var(--body-text-x-small);

  ${({ highlighted = false }) => {
    if (highlighted) {
      return css`
        color: var(--white);
      `;
    }
    return css`
      color: var(--text-color-dim);
    `;
  }}
`;

interface CircleIconProps {
  color: string;
}

export const Circle = styled.div<CircleIconProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: var(--border-radius-large);
  width: 18px;
  height: 18px;
`;

export const TopicIcon = styled(Icon).attrs({
  size: 18,
})``;

export const ZeroState = styled.div`
  padding: var(--spacing-6) 0;
`;

export const ZeroStateText = styled.div`
  max-width: 600px;

  h2 {
    padding-bottom: var(--spacing-1);
  }
`;

export const ZeroStateCards = styled.div`
  display: flex;
  gap: var(--spacing-4);
  padding: var(--spacing-8) 0 0;

  ${MediaQueries.phoneOnly} {
    flex-direction: column;
  }
`;

export const ZeroStateCard = styled.article`
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-small);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-4);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  flex: 1 1;
`;

export const ViewSelectorWrapper = styled.div`
  height: 24px;
  width: 252px;
  opacity: 1;
  position: absolute;
  z-index: 101;
  right: 54px;
  top: 13px;
  transition: opacity var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);
`;
